$project-details-background: #f2f2f2;
$banner-height: 98vh;

.projectlist{
  padding-bottom: 500px;
  position: relative;
  background-color: $secondary;

  $description-width: 46%;

  .content{
    padding-top: 8%;
    margin: auto;
    position: relative;

    &::before{
      content: '';
      position: absolute;
      right: 10%;
      top: 0;
      height: 0px;
      width: 2px;
      background-color: white;
      transition: height 0.6s ease;
    }

    &.line::before{
      height: 500px;
    }

    .project{
      color: white;
      font-family: 'Source Sans Pro';

      display: flex;
      margin-top: 260px;

      transition: all 0.6s ease;
      transition-property: opacity, transform;

      transform: translateY(40px);
      opacity: 0;

      &.active{
        transform: translateY(0);
        opacity: 1;

        .basics h6::before{ width: 0!important; }

        &.horizontal{
          h1{
            &::before{
              margin-top: 0;
            }
          }

          .viewmore{

            text-decoration: none;
            user-select: none;
            top: 10px;

            &::before{
              width: 140px!important;
              // transition: width 0.8s ease 0.8s;
            }

            &::after{
              width: 100%!important;
              // transition: width 0.8s ease 1.2s;
            }
          }
        }

        &.vertical{
          .basics{

            .cover{
              // delayed 2nd cover reveal
              height: 0;
              transition: all 1.2s ease 0.6s;
            }

            &::after{
              height: 0;
              transition: all 1.2s ease 0.4s;
            }
          }

          .viewmore{

            text-decoration: none;
            user-select: none;
            top: 10px;

            &::before{
              width: 140px!important;
              // transition: width 0.8s ease 0.8s;
            }

            &::after{
              width: 100%!important;
              // transition: width 0.8s ease 1.2s;
            }
          }
        }

        .descriptions{
          opacity: 1!important;
        }
      }

      &.horizontal{
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        h1{
          font-size: 45px;
          text-transform: uppercase;
          letter-spacing: 4px;
          line-height: 60px;

          width: fit-content;
          padding: 0 25px;
          position: relative;
          z-index: 0;

          &::before{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;

            width: 100%;
            height: 28px;

            margin-top: 50px;

            background-size: cover;
            background-position: top;
            transition: all 2.4s ease;
          }
        }

        &:nth-child(1) h1:nth-child(1)::before{
          $image-size: 320px;

          content: '';
          position: absolute;
          top: -200px;
          left: -35%;
          right: 0;
          width: 620px!important;
          height: 620px!important;

          width: $image-size;
          height: $image-size;
          background-image: url('../assets/images/backgrounds/run-ottawa.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;

          transform: translateY(30px);
          animation: translateUp 2s forwards 1s;

          filter: brightness(0.9);
        }

        &:nth-child(3) h1:nth-child(1)::before{
          $image-size: 320px;

          content: '';
          position: absolute;
          top: -158px;
          // left: 70px;
          left: unset;
          right: 0;

          width: $image-size;
          height: $image-size;
          background-image: url('../assets/images/backgrounds/kruger-box.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;

          transform: translateY(30px);
          animation: translateUp 2s forwards 1s;

          filter: brightness(0.9);
        }

        &:nth-child(5) h1:nth-child(1)::before{

          $image-size: 340px;

          content: '';
          position: absolute;
          top: -60px;
          // left: 160px; // caused overflow scroll
          left: unset;
          right: 0;

          width: $image-size;
          height: $image-size;
          background-image: url('../assets/images/backgrounds/kfc-statue2.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;

          transform: translateY(30px);
          animation: translateUp 2s forwards 1s;

        }

        &:nth-child(7) h1:nth-child(1)::before{

          $image-size: 260px;

          content: '';
          position: absolute;
          top: -60px;
          // left: 160px;
          left: unset;
          right: 0;

          width: $image-size;
          height: $image-size;
          background-image: url('../assets/images/backgrounds/rexel-cable.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;

          transform: translateY(30px);
          animation: translateUp 2s forwards 1s;
        }

        &:nth-child(9) h1:nth-child(1)::before{

          $image-size: 340px;

          content: '';
          position: absolute;
          top: -80px;
          // left: 120px;
          left: unset;
          right: 0;

          width: $image-size;
          height: $image-size;
          background-image: url('../assets/images/backgrounds/carrot-phone2.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;

          transform: translateY(30px);
          animation: translateUp 2s forwards 1s;
        }

        h5{
          font-family: Poppins;
          font-weight: 200;
          font-size: 14px;
          letter-spacing: 2px;
          line-height: 20px;

          margin: 10px 0;
        }

        .basics{
          flex: 1;
          max-width: calc(100% - #{$description-width});

          h1{
            margin: 0;
            padding: 0;
          }

          h6{
            &::before{
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;
              background-color: $secondary;

              transition: width 0.6s ease 1s;
            }
          }

          .info{
            font-family: Poppins;
            font-size: 8px;
            text-transform: uppercase;
            letter-spacing: 4px;
            line-height: 14px;
            text-align: left;

            width: fit-content;

            transform-origin: bottom left;
            transform: rotate(90deg);

            h6{ margin: 0; }
          }
        }

        .descriptions{
          margin-top: 180px;
          text-align: right;
          max-width: 280px;

          opacity: 0;
          transition: opacity 0.4s ease;

          .viewmore{
            font-family: Poppins;
            font-weight: 100;
            font-size: 14px;
            color: $secondary;

            width: fit-content;
            margin-left: auto;
            padding: 3px 16px;
            padding-top: 6px;
            position: relative;
            transition: color 0.2s ease;

            cursor: default;

            &:hover{ color: white; }

            &::before{
              content: '';
              position: absolute;
              left: -150px;
              top: 50%;
              transform: translateY(-50%);
              width: 0;
              height: 1px;
              background: white;
            }

            &::after{
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 0;
              height: 100%;
              background-color: $primary;
              z-index: -1;
            }
          }
        }
      }

      &.vertical{
        flex-direction: row-reverse;

        h1{
          font-size: 36px;
          text-transform: uppercase;
          letter-spacing: 4px;
          line-height: 60px;

          width: fit-content;
          position: relative;
          z-index: 0;

          margin: 0;
        }

        h2{
          margin-top: 50px;
        }

        h5{
          font-family: Poppins;
          font-weight: 200;
          font-size: 14px;
          letter-spacing: 2px;
          line-height: 20px;

          margin: 10px 0;
          width: 260px;
        }

        .basics{
          min-width: $description-width;
          min-height: 300px;
          background-size: cover;
          position: relative;

          .cover{
            position: absolute;
            width: 75%;
            height: 100%;
            left: 25%;
            bottom: 0;

            background-color: $secondary;

          }

          &::before{
            // content: '';

            width: 5%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 25%;

            background-color: $secondary;
          }

          &::after{
            content: '';

            width: 100%;
            height: 100%;

            position: absolute;
            bottom: 0;
            left: 0;

            background-color: $secondary;
          }

          .cover{

          }
        }

        .descriptions{
          flex: 1;
          margin-left: 10%;

          .viewmore{
            font-family: Poppins;
            font-weight: 100;
            font-size: 14px;
            color: $secondary;

            width: fit-content;
            margin-right: auto;
            padding: 3px 16px;
            padding-top: 6px;
            position: relative;
            cursor: default;
            transition: color 0.2s ease;

            &:hover{ color: white; }

            &::before{
              content: '';
              position: absolute;
              left: 110%;
              top: 50%;
              transform: translateY(-50%);
              width: 0;
              height: 1px;
              background: white;
            }

            &::after{
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 0;
              height: 100%;
              background-color: $primary;
              z-index: -1;
            }
          }

          h2,
          h5
          { text-align: left; }
        }

      }

      &.visible{
        opacity: 1;
        transform: translateY(0);
      }

    }
  }
}

.projectcontainer{
  position: relative;
  height: 100%;
}

@media screen and (max-width: $screen-l){
  .projectlist{
    .content{
      .project{
        padding: 0 10%;

        &.vertical,
        &.horizontal{

          h1{
            font-size: 35px;
            line-height: 45px;
            text-align: left;
          }

          .descriptions{
            margin-left: 0;

            .viewmore::before{ display: none; }
          }
        }

        &.vertical{
          padding-right: 0;

          h5{
            margin: 20px 0;
          }

          .basics{
            margin-top: 30px;
          }
        }

        &.horizontal{
          $image-size: 240px;

          h2{
            margin: 20px 0;
          }

          &:nth-child(4) h1:nth-child(1)::before{
            width: $image-size;
            height: $image-size;
          }

          .descriptions{
            margin-left: auto;
          }

        }

      }
    }
  }
}

@media screen and (max-width: $screen-m){
  .projectlist{
    .content{
      .project{
        
        display: flex;
        flex-direction: column;

        .descriptions{
          margin-top: 120px;
          margin-left: auto;
          margin-right: auto;

          text-align: center;

          h2{
            font-family: Poppins;
            font-size: 18px;
          }

          .viewmore{ margin: auto; }
        }

        &.vertical{
          flex-direction: column;
          padding-left: 0;
          padding: 0 3%;

          .basics{
            min-height: 260px;

            .cover{
              width: 100%;
              height: 100%!important;
              left: unset!important;
              right: 0;
            }

            &::after{ display: none; } // hide the smaller cover
            &::before{ display: none; }
          }

          .descriptions{
            margin-left: auto;

            h2{
              text-align: center;
              margin: 20px auto;
            }

            h5{
              display: none;
            }
          }

        }

        &.horizontal{

          h5{
            display: none;
          }
          h1{
            position: unset;
            text-align: left;
          }

          .descriptions{
            position: relative;

            h2{ text-align: center; }

            .viewmore{
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          &:nth-child(1) h1:nth-child(1)::before{
            left: 0;
            width: 460px!important;
            height: 460px!important;
          }

        }

        .viewmore{
          min-width: 80px;
          text-align: center;
        }

        &.active.vertical .basics .cover{
          width: 0;
          transition-delay: 0.4s; // slide covers all at once
        }

      }
    }
  }

}
