@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,300i,400,600,700|Source+Sans+Pro:300,300i,400,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-moz-selection {
  background-color: #989898;
}
::selection {
  background-color: #989898;
}

html, body {
  background-color: #111111;
}

::-webkit-scrollbar {
  display: none;
}

#root {
  -webkit-transition: opacity 800ms;
  transition: opacity 800ms;
}

#hc-logo {
  position: absolute;
  width: 45px;
  height: 20px;
  margin: 45px;
  z-index: 100;
  overflow: hidden;
}
#hc-logo img {
  width: 100%;
  height: 20px;
  object-fit: contain;
  display: block;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
  transition: -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
}
#hc-logo.dark img {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.backgroundscene {
  pointer-events: none;
  position: absolute;
  -webkit-transform: scale(1);
          transform: scale(1);
  background: #111111;
}

.backgroundcover-left,
.backgroundcover-right {
  width: 0%;
  height: 100vh;
  position: absolute;
  background-color: white;
}
.backgroundcover-left.backgroundcover-left,
.backgroundcover-right.backgroundcover-left {
  left: 0;
}
.backgroundcover-left.backgroundcover-right,
.backgroundcover-right.backgroundcover-right {
  right: 0;
}

#content {
  z-index: 10;
  position: absolute;
  width: 100vw;
}

.menus * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.menus #menuIndicator {
  position: absolute;
  left: 20px;
  top: 64px;
  width: 58px;
  height: 6px;
  background-color: #e8bf2f;
  opacity: 1;
  width: 0;
  z-index: 11;
}
.menus .menu {
  z-index: 11;
  color: black;
  position: absolute;
  left: 50%;
  top: 60px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  mix-blend-mode: normal;
}
.menus .menu.edge {
  color: grey !important;
}
.menus .menu a, .menus .menu button {
  text-decoration: none;
  color: inherit;
  cursor: default;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.menus .menu a:hover, .menus .menu button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.menus .menu button {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  outline: none;
  font-size: inherit;
}
.menus .menu button:focus, .menus .menu button span:focus {
  outline: none;
}
.menus .menu button:focus span {
  position: relative;
  bottom: 1px;
  outline: -webkit-focus-ring-color auto 1px;
}
.menus .menu button {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  outline: none;
  font-size: inherit;
}
.menus .menu button:focus, .menus .menu button span:focus {
  outline: none;
}
.menus .menu button:focus span {
  position: relative;
  bottom: 1px;
  outline: -webkit-focus-ring-color auto 1px;
}
.menus .menu ul {
  display: flex;
  padding: 0;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.menus .menu ul li {
  list-style: none;
  margin: 0px 20px;
  font-size: 18px;
  font-family: "Source Sans Pro";
  position: relative;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  white-space: nowrap;
  cursor: default;
  box-sizing: content-box;
}
.menus .menu ul li.language-toggle {
  color: rgb(197, 197, 197);
}
.menus .menu ul li #merge-logo {
  width: 69px;
  position: relative;
  top: 6px;
}
.menus .menu ul li #merge-logo {
  width: 69px;
  position: relative;
  top: 6px;
}
.menus .burger {
  display: flex;
  display: none;
  padding: 0 10px;
  position: absolute;
  right: 4%;
  top: 45px;
  z-index: 100;
  mix-blend-mode: exclusion;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.menus .burger .icon {
  width: 30px;
  height: 30px;
  position: relative;
  pointer-events: none;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
.menus .burger .icon::before, .menus .burger .icon::after {
  content: "";
  width: 60%;
  height: 1px;
  background-color: white;
  mix-blend-mode: exclusion;
  position: absolute;
  -webkit-transform: translate(0px, 10px);
          transform: translate(0px, 10px);
  z-index: 1;
}
.menus .burger .icon::before {
  top: 0;
}
.menus .burger .icon::after {
  top: 6px;
}
.menus .burger h4 {
  mix-blend-mode: exclusion;
  color: white;
  margin: 0;
  pointer-events: none;
  font-size: 15px;
  font-family: Poppins;
  font-weight: 300;
  align-self: center;
  letter-spacing: 1px;
  position: relative;
  z-index: 100;
}
.menus .menu-full {
  background-color: #222222;
  position: absolute;
  left: -100vw;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: grid;
  display: none;
  grid-template-columns: auto 230px;
}
.menus .menu-full #content {
  flex: 1 1;
  min-height: 100vh;
}
.menus .menu-full #content #back {
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-top: 10px;
  padding: 25px;
  font-size: 1rem;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.menus .menu-full #content #back:hover {
  opacity: 1 !important;
}
.menus .menu-full #content ul {
  max-width: 400px;
  margin: 0 8%;
  list-style: none;
  line-height: 5rem;
  font-size: 3.5rem;
  text-align: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0;
}
.menus .menu-full #content ul a, .menus .menu-full #content ul button {
  text-decoration: none;
  text-transform: capitalize;
  all: unset;
}
.menus .menu-full #content ul li {
  color: white;
  font-family: Source Sans Pro;
  font-weight: 100;
  opacity: 0.25;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  cursor: pointer;
}
.menus .menu-full #content ul li.active {
  opacity: 1;
}
.menus .menu-full #content ul #merge-logo {
  height: 45px;
  position: relative;
  top: 14px;
}
.menus .menu-full #content #location {
  position: absolute;
  bottom: 40px;
  right: 50px;
  color: white;
  font-size: 1rem;
  letter-spacing: 1px;
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  display: none;
}
.menus .menu-full #content #location h4 {
  margin: 0;
  font-family: Source Sans Pro;
  font-weight: 300;
}
.menus .menu-full #content #location h4:first-child {
  margin-bottom: 10px;
}
.menus .menu-full #map {
  opacity: 0.13;
  margin-left: auto;
  position: absolute;
  right: 0;
  overflow: hidden;
  width: 160px;
  height: 100vh;
}
.menus .menu-full #map #cover {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #222222;
  z-index: 1;
}
.menus .menu-full #map #mapscene {
  height: 100%;
}
.menus .menu-full #map img {
  margin-top: -20%;
  margin-left: -20%;
  width: 140%;
  height: 140%;
  object-fit: cover;
}

.cursor.main {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #333333;
  mix-blend-mode: exclusion;
  opacity: 0;
  z-index: 100;
  pointer-events: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transition-property: width, height, opacity;
  transition-property: width, height, opacity;
}
.cursor.progress {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  stroke: white;
  stroke-width: 2px;
  stroke-dasharray: 1000;
  stroke-dashoffset: 2000;
  fill: transparent;
  -webkit-transition: stroke-dasharray 0.2s ease;
  transition: stroke-dasharray 0.2s ease;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.cursor.hidden {
  opacity: 0;
}
.cursor .description {
  position: absolute;
  top: 100%;
  width: 100%;
  color: white;
  font-family: Poppins;
  font-weight: 100;
  text-align: center;
  overflow: visible;
  background-blend-mode: difference;
}

@media screen and (max-width: 740px) {
  .cursor {
    display: none !important;
  }
}
.loader {
  width: 100%;
  height: 100%;
  background-color: #111111;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
}
.loader .cover {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 102;
}
.loader .cover .tag {
  color: #111111;
  position: relative;
  left: 50%;
  top: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loader .cover::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0;
  left: 0;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  -webkit-transition-property: width, left;
  transition-property: width, left;
}
.loader .cover::after {
  content: "";
}
.loader .cover .message {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 1rem;
  width: 90%;
  text-align: center;
  opacity: 0;
}
.loader .content {
  position: absolute;
  top: 52%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 30px !important;
  overflow: hidden;
  display: flex;
  height: 50px;
  padding-top: 20px;
  background-color: #111111;
}
.loader .content::before {
  content: "";
  width: 100%;
  height: 40px;
  background-color: #111111;
  top: 100%;
  bottom: 0;
  position: absolute;
  z-index: 101;
}
.loader .content .bar {
  margin-top: 4px;
  margin-right: 10px;
  width: 200px;
  height: 1px;
  position: relative;
}
.loader .content .bar::before, .loader .content .bar::after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.loader .content .bar::before {
  background-color: #111111;
  z-index: 102;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.loader .content .bar::after {
  background-color: white;
  -webkit-animation: loadbar 1.6s 0.6s ease forwards;
          animation: loadbar 1.6s 0.6s ease forwards;
}
.loader .content .thumb {
  width: 50px;
  min-width: 50px;
  height: 30px;
  margin-right: 8px;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  opacity: 0;
  -webkit-animation: thumbfadein 0.4s 0.25s forwards;
          animation: thumbfadein 0.4s 0.25s forwards;
}
.loader .content .thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.loader .content h4 {
  color: white;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
  margin: 0;
  margin-top: -4px;
  overflow: hidden;
  min-width: 200px;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-animation: loadtext 0.4s ease 1s forwards;
          animation: loadtext 0.4s ease 1s forwards;
}
.loader.start .cover::before {
  width: 100%;
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}
.loader.start .cover .thumb {
  opacity: 1;
}
.loader.start .content .bar::before {
  width: 100%;
}

@media screen and (max-width: 500px) {
  .loader .content .bar, .loader .content .thumb {
    display: none;
  }
  .loader .content h4 {
    text-align: center;
  }
}
@-webkit-keyframes loadbar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes loadbar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes loadbarout {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@keyframes loadbarout {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
@-webkit-keyframes loadtext {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes loadtext {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@-webkit-keyframes thumbfadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes thumbfadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes translateUp {
  0% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes translateUp {
  0% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
.apploaded #landingcover {
  -webkit-backdrop-filter: blur(0) !important;
          backdrop-filter: blur(0) !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

#landingbackground {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
#landingbackground video {
  min-width: 100%;
}
#landingbackground #landingcover {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
#landingbackground #landingcover.loaded {
  -webkit-backdrop-filter: blur(0);
          backdrop-filter: blur(0);
  background-color: rgba(0, 0, 0, 0.2);
}
#landingbackground #img-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#landingbackground #img-logo {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 38%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  min-width: 240px;
}

#aboutcontainer {
  display: grid;
  grid-template-columns: 0.55fr 0.45fr;
  min-height: 100vh;
}
#aboutcontainer #aboutcontent {
  height: 100%;
  width: 100%;
  background-color: white;
  display: grid;
  align-items: center;
  position: relative;
  opacity: 0;
  -webkit-animation: fadeIn 0.5s forwards 0.8s;
          animation: fadeIn 0.5s forwards 0.8s;
}
#aboutcontainer #aboutcontent #description {
  padding: 0 12%;
  margin: auto;
  align-self: center;
  font-size: 1.1rem;
  line-height: 1.8rem;
  opacity: 0;
}
#aboutcontainer #aboutcontent #logos {
  padding: 0 12%;
}
#aboutcontainer #aboutcontent #logos #logoscontainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  align-items: center;
}
#aboutcontainer #aboutcontent #logos #logoscontainer .logo {
  width: 80%;
  margin: auto;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  opacity: 0;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
#aboutcontainer #aboutcontent #logos #logoscontainer .logo:hover {
  -webkit-transform: scale(1.15) !important;
          transform: scale(1.15) !important;
}
#aboutcontainer #aboutcontent #logos #logoscontainer img {
  width: 100%;
  object-fit: contain;
}
#aboutcontainer #aboutcontent #aboutnavigation {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
}
#aboutcontainer #aboutcontent #aboutnavigation #line {
  width: 0%;
  height: 1px;
  background-color: #111111;
  display: flex;
  margin: auto 20px;
  margin-left: auto;
  -webkit-animation: lineStretch 0.8s forwards 0.8s;
          animation: lineStretch 0.8s forwards 0.8s;
}
#aboutcontainer #aboutcontent #aboutnavigation #options {
  display: flex;
  margin-right: 35px;
}
#aboutcontainer #aboutcontent #aboutnavigation #options h4 {
  font-family: Poppins;
  position: relative;
  text-align: right;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
#aboutcontainer #aboutcontent #aboutnavigation #options h4:nth-child(2) {
  margin-left: 20px;
}
#aboutcontainer #aboutcontent #aboutnavigation #options h4::before {
  content: attr(data-index);
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(0%, -100%);
          transform: translate(0%, -100%);
  font-size: 0.6rem;
}
#aboutcontainer #aboutcontent #aboutnavigation #options h4:hover {
  opacity: 0.2;
}
#aboutcontainer #slogan {
  height: 100%;
  background-color: #111111;
  overflow: hidden;
}
#aboutcontainer #slogan video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
#aboutcontainer #slogan h1 {
  text-transform: uppercase;
  font-family: Poppins;
  font-weight: 600;
  font-size: 10vh;
  line-height: 10vh;
  color: rgba(255, 255, 255, 0.25);
  mix-blend-mode: screen;
  padding-right: 40px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(10px, -50%);
          transform: translate(10px, -50%);
  margin: 0;
}

@media screen and (max-width: 1200px) {
  #aboutcontainer #aboutcontent #logos #logoscontainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  #aboutcontainer #aboutcontent #logos #logoscontainer .logo {
    width: 70%;
  }
  #aboutcontainer #slogan h1 {
    font-size: 10vh;
    line-height: 10vh;
    max-width: 480px;
  }
}
@media screen and (max-width: 1000px) {
  #aboutcontainer #aboutcontent #logos #logoscontainer img {
    width: 60px;
  }
  #aboutcontainer #aboutcontent #aboutnavigation #line {
    width: 200px;
    -webkit-transition: width 0.2s ease;
    transition: width 0.2s ease;
  }
}
@media screen and (max-width: 860px) {
  #aboutcontainer {
    display: flex;
    flex-direction: column-reverse;
    min-height: unset;
  }
  #aboutcontainer #slogan {
    width: 100%;
    position: relative;
    min-height: 600px;
  }
  #aboutcontainer #slogan h1 {
    position: absolute;
    left: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 8vw;
    line-height: 8vw;
  }
  #aboutcontainer #aboutcontent {
    min-height: 100vh;
    grid-template-rows: 1fr auto;
  }
  #aboutcontainer #aboutcontent #logos #logoscontainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  #aboutcontainer #aboutcontent #logos #logoscontainer .logo {
    width: 50%;
  }
  #aboutcontainer #aboutcontent #aboutnavigation {
    position: unset;
  }
  #aboutcontainer #aboutcontent #aboutnavigation #line {
    display: none;
  }
  #aboutcontainer #aboutcontent #aboutnavigation #options {
    margin: auto;
  }
  #aboutcontainer #aboutcontent #aboutnavigation #options h4 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 700px) {
  #aboutcontainer #aboutcontent #logos #logoscontainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-height: 700px) {
  #logoscontainer .logo {
    max-height: 60px;
  }
}
@media screen and (min-height: 850px) {
  #aboutcontainer #aboutcontent #aboutnavigation #options h4 {
    font-size: 1.5rem;
  }
  #aboutcontainer #aboutcontent #description {
    font-size: 1.4rem;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes lineStretch {
  0% {
    width: 0%;
  }
  100% {
    width: 50%;
  }
}
@keyframes lineStretch {
  0% {
    width: 0%;
  }
  100% {
    width: 50%;
  }
}
.workcontainer {
  position: relative;
}
.workcontainer #workscrollbar {
  position: fixed;
  top: 100vh;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  width: 100%;
  height: 5px;
  background-color: #555555;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}
.workcontainer #workscrollbar.visible {
  opacity: 1;
}
.workcontainer #workcursor {
  position: fixed;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #111111;
  z-index: 10;
  pointer-events: none;
}
.workcontainer #workcursor.lock {
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}
.workcontainer .main {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
}
.workcontainer .main h1 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 1.8rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  position: absolute;
  right: 120px;
  top: 50vh;
  -webkit-transform: translateY(calc(-50% + 20px));
          transform: translateY(calc(-50% + 20px));
  opacity: 0;
}
.workcontainer .main .openprojects {
  font-family: Poppins;
  font-weight: 100;
  font-size: 0.85rem;
  letter-spacing: 1px;
  color: black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 15px;
  position: absolute;
  right: 80px;
  top: 70vh;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  cursor: pointer;
}
.workcontainer .main .openprojects:hover {
  color: white;
}
.workcontainer .main .openprojects::before {
  content: "";
  position: absolute;
  left: 110%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 0;
  height: 2px;
  background-color: white;
  -webkit-transition: width 1s ease 0.6s;
  transition: width 1s ease 0.6s;
}
.workcontainer .main .openprojects::after {
  content: "";
  background-color: #e8bf2f;
  width: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  -webkit-transition: width 0.6s ease 0.2s;
  transition: width 0.6s ease 0.2s;
}
.workcontainer .main .openprojects.inverted::before {
  background-color: #111111;
}
.workcontainer .main .openprojects.active::before {
  width: 50vh;
}
.workcontainer .main .openprojects.active::after {
  width: 100%;
}
.workcontainer .main #scene {
  margin: auto;
  width: 70vw;
  top: 32%;
  opacity: 0.25;
}
.workcontainer .main #scene .sceneimg:nth-child(2) {
  margin-top: 65px;
  margin-left: 60px;
}
.workcontainer .main #scene .sceneimg img {
  max-width: 25vw;
}
.workcontainer .main .workscenereplacement {
  position: absolute;
  left: 10%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  max-width: 80%;
  width: 500px;
  z-index: -1;
  display: none;
}
.workcontainer .main .workscenereplacement img {
  width: 100%;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}
.workcontainer .personalmessage {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  position: absolute;
  top: 50vh;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 1.25rem;
  font-family: Poppins;
  padding: 14px 25px;
  padding-top: 16px;
  opacity: 0;
}
.workcontainer .backproject {
  position: absolute;
  right: 0;
  top: 0;
  margin: 40px;
  color: white;
  opacity: 0.8;
  z-index: 100;
  cursor: pointer;
  opacity: 0;
}

@media screen and (max-width: 1070px) {
  .work .main {
    position: relative;
  }
  .work .main h1 {
    right: 50%;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    font-family: Poppins;
  }
  .work .main .openprojects {
    bottom: unset;
    top: -280px;
  }
}
@media screen and (max-width: 740px) {
  .workcontainer .main h1 {
    left: 50%;
    -webkit-transform: translate(-50%, -50px) !important;
            transform: translate(-50%, -50px) !important;
  }
  .workcontainer .main .openprojects {
    right: 20px;
  }
}
@media screen and (max-height: 500px) {
  .workcontainer .main h1 {
    left: 50%;
    -webkit-transform: translate(-50%, -50px) !important;
            transform: translate(-50%, -50px) !important;
  }
  .workcontainer .main .openprojects {
    right: 0;
  }
}
@media screen and (min-width: 1900px) {
  .workcontainer .main {
    max-width: 2200px;
  }
  .workcontainer .main h1 {
    font-size: 2rem;
  }
  .workcontainer .main .workscenereplacement {
    left: 34%;
  }
  .projectlist .content {
    max-width: 1500px;
    margin: auto;
  }
  .projectlist .content .project {
    max-width: 1200px !important;
  }
  .projectlist .content .project.horizontal .descriptions {
    margin-left: 120px;
  }
}
.projectlist {
  padding-bottom: 500px;
  position: relative;
  background-color: #111111;
}
.projectlist .content {
  padding-top: 8%;
  margin: auto;
  position: relative;
}
.projectlist .content::before {
  content: "";
  position: absolute;
  right: 10%;
  top: 0;
  height: 0px;
  width: 2px;
  background-color: white;
  -webkit-transition: height 0.6s ease;
  transition: height 0.6s ease;
}
.projectlist .content.line::before {
  height: 500px;
}
.projectlist .content .project {
  color: white;
  font-family: "Source Sans Pro";
  display: flex;
  margin-top: 260px;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  opacity: 0;
}
.projectlist .content .project.active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}
.projectlist .content .project.active .basics h6::before {
  width: 0 !important;
}
.projectlist .content .project.active.horizontal h1::before {
  margin-top: 0;
}
.projectlist .content .project.active.horizontal .viewmore {
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  top: 10px;
}
.projectlist .content .project.active.horizontal .viewmore::before {
  width: 140px !important;
}
.projectlist .content .project.active.horizontal .viewmore::after {
  width: 100% !important;
}
.projectlist .content .project.active.vertical .basics .cover {
  height: 0;
  -webkit-transition: all 1.2s ease 0.6s;
  transition: all 1.2s ease 0.6s;
}
.projectlist .content .project.active.vertical .basics::after {
  height: 0;
  -webkit-transition: all 1.2s ease 0.4s;
  transition: all 1.2s ease 0.4s;
}
.projectlist .content .project.active.vertical .viewmore {
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  top: 10px;
}
.projectlist .content .project.active.vertical .viewmore::before {
  width: 140px !important;
}
.projectlist .content .project.active.vertical .viewmore::after {
  width: 100% !important;
}
.projectlist .content .project.active .descriptions {
  opacity: 1 !important;
}
.projectlist .content .project.horizontal {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.projectlist .content .project.horizontal h1 {
  font-size: 45px;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 60px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 25px;
  position: relative;
  z-index: 0;
}
.projectlist .content .project.horizontal h1::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 28px;
  margin-top: 50px;
  background-size: cover;
  background-position: top;
  -webkit-transition: all 2.4s ease;
  transition: all 2.4s ease;
}
.projectlist .content .project.horizontal:nth-child(1) h1:nth-child(1)::before {
  content: "";
  position: absolute;
  top: -200px;
  left: -35%;
  right: 0;
  width: 620px !important;
  height: 620px !important;
  width: 320px;
  height: 320px;
  background-image: url(/static/media/run-ottawa.624cd796.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-animation: translateUp 2s forwards 1s;
          animation: translateUp 2s forwards 1s;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}
.projectlist .content .project.horizontal:nth-child(3) h1:nth-child(1)::before {
  content: "";
  position: absolute;
  top: -158px;
  left: unset;
  right: 0;
  width: 320px;
  height: 320px;
  background-image: url(/static/media/kruger-box.39fb6d58.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-animation: translateUp 2s forwards 1s;
          animation: translateUp 2s forwards 1s;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}
.projectlist .content .project.horizontal:nth-child(5) h1:nth-child(1)::before {
  content: "";
  position: absolute;
  top: -60px;
  left: unset;
  right: 0;
  width: 340px;
  height: 340px;
  background-image: url(/static/media/kfc-statue2.1222f8db.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-animation: translateUp 2s forwards 1s;
          animation: translateUp 2s forwards 1s;
}
.projectlist .content .project.horizontal:nth-child(7) h1:nth-child(1)::before {
  content: "";
  position: absolute;
  top: -60px;
  left: unset;
  right: 0;
  width: 260px;
  height: 260px;
  background-image: url(/static/media/rexel-cable.7085dcfe.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-animation: translateUp 2s forwards 1s;
          animation: translateUp 2s forwards 1s;
}
.projectlist .content .project.horizontal:nth-child(9) h1:nth-child(1)::before {
  content: "";
  position: absolute;
  top: -80px;
  left: unset;
  right: 0;
  width: 340px;
  height: 340px;
  background-image: url(/static/media/carrot-phone2.35d34c83.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-animation: translateUp 2s forwards 1s;
          animation: translateUp 2s forwards 1s;
}
.projectlist .content .project.horizontal h5 {
  font-family: Poppins;
  font-weight: 200;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 20px;
  margin: 10px 0;
}
.projectlist .content .project.horizontal .basics {
  flex: 1 1;
  max-width: calc(100% - 46%);
}
.projectlist .content .project.horizontal .basics h1 {
  margin: 0;
  padding: 0;
}
.projectlist .content .project.horizontal .basics h6::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #111111;
  -webkit-transition: width 0.6s ease 1s;
  transition: width 0.6s ease 1s;
}
.projectlist .content .project.horizontal .basics .info {
  font-family: Poppins;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 14px;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.projectlist .content .project.horizontal .basics .info h6 {
  margin: 0;
}
.projectlist .content .project.horizontal .descriptions {
  margin-top: 180px;
  text-align: right;
  max-width: 280px;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}
.projectlist .content .project.horizontal .descriptions .viewmore {
  font-family: Poppins;
  font-weight: 100;
  font-size: 14px;
  color: #111111;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  padding: 3px 16px;
  padding-top: 6px;
  position: relative;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  cursor: default;
}
.projectlist .content .project.horizontal .descriptions .viewmore:hover {
  color: white;
}
.projectlist .content .project.horizontal .descriptions .viewmore::before {
  content: "";
  position: absolute;
  left: -150px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 0;
  height: 1px;
  background: white;
}
.projectlist .content .project.horizontal .descriptions .viewmore::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #e8bf2f;
  z-index: -1;
}
.projectlist .content .project.vertical {
  flex-direction: row-reverse;
}
.projectlist .content .project.vertical h1 {
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 60px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  z-index: 0;
  margin: 0;
}
.projectlist .content .project.vertical h2 {
  margin-top: 50px;
}
.projectlist .content .project.vertical h5 {
  font-family: Poppins;
  font-weight: 200;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 20px;
  margin: 10px 0;
  width: 260px;
}
.projectlist .content .project.vertical .basics {
  min-width: 46%;
  min-height: 300px;
  background-size: cover;
  position: relative;
}
.projectlist .content .project.vertical .basics .cover {
  position: absolute;
  width: 75%;
  height: 100%;
  left: 25%;
  bottom: 0;
  background-color: #111111;
}
.projectlist .content .project.vertical .basics::before {
  width: 5%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 25%;
  background-color: #111111;
}
.projectlist .content .project.vertical .basics::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #111111;
}
.projectlist .content .project.vertical .descriptions {
  flex: 1 1;
  margin-left: 10%;
}
.projectlist .content .project.vertical .descriptions .viewmore {
  font-family: Poppins;
  font-weight: 100;
  font-size: 14px;
  color: #111111;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-right: auto;
  padding: 3px 16px;
  padding-top: 6px;
  position: relative;
  cursor: default;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
.projectlist .content .project.vertical .descriptions .viewmore:hover {
  color: white;
}
.projectlist .content .project.vertical .descriptions .viewmore::before {
  content: "";
  position: absolute;
  left: 110%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 0;
  height: 1px;
  background: white;
}
.projectlist .content .project.vertical .descriptions .viewmore::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #e8bf2f;
  z-index: -1;
}
.projectlist .content .project.vertical .descriptions h2,
.projectlist .content .project.vertical .descriptions h5 {
  text-align: left;
}
.projectlist .content .project.visible {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.projectcontainer {
  position: relative;
  height: 100%;
}

@media screen and (max-width: 1070px) {
  .projectlist .content .project {
    padding: 0 10%;
  }
  .projectlist .content .project.vertical h1, .projectlist .content .project.horizontal h1 {
    font-size: 35px;
    line-height: 45px;
    text-align: left;
  }
  .projectlist .content .project.vertical .descriptions, .projectlist .content .project.horizontal .descriptions {
    margin-left: 0;
  }
  .projectlist .content .project.vertical .descriptions .viewmore::before, .projectlist .content .project.horizontal .descriptions .viewmore::before {
    display: none;
  }
  .projectlist .content .project.vertical {
    padding-right: 0;
  }
  .projectlist .content .project.vertical h5 {
    margin: 20px 0;
  }
  .projectlist .content .project.vertical .basics {
    margin-top: 30px;
  }
  .projectlist .content .project.horizontal h2 {
    margin: 20px 0;
  }
  .projectlist .content .project.horizontal:nth-child(4) h1:nth-child(1)::before {
    width: 240px;
    height: 240px;
  }
  .projectlist .content .project.horizontal .descriptions {
    margin-left: auto;
  }
}
@media screen and (max-width: 740px) {
  .projectlist .content .project {
    display: flex;
    flex-direction: column;
  }
  .projectlist .content .project .descriptions {
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .projectlist .content .project .descriptions h2 {
    font-family: Poppins;
    font-size: 18px;
  }
  .projectlist .content .project .descriptions .viewmore {
    margin: auto;
  }
  .projectlist .content .project.vertical {
    flex-direction: column;
    padding-left: 0;
    padding: 0 3%;
  }
  .projectlist .content .project.vertical .basics {
    min-height: 260px;
  }
  .projectlist .content .project.vertical .basics .cover {
    width: 100%;
    height: 100% !important;
    left: unset !important;
    right: 0;
  }
  .projectlist .content .project.vertical .basics::after {
    display: none;
  }
  .projectlist .content .project.vertical .basics::before {
    display: none;
  }
  .projectlist .content .project.vertical .descriptions {
    margin-left: auto;
  }
  .projectlist .content .project.vertical .descriptions h2 {
    text-align: center;
    margin: 20px auto;
  }
  .projectlist .content .project.vertical .descriptions h5 {
    display: none;
  }
  .projectlist .content .project.horizontal h5 {
    display: none;
  }
  .projectlist .content .project.horizontal h1 {
    position: unset;
    text-align: left;
  }
  .projectlist .content .project.horizontal .descriptions {
    position: relative;
  }
  .projectlist .content .project.horizontal .descriptions h2 {
    text-align: center;
  }
  .projectlist .content .project.horizontal .descriptions .viewmore {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .projectlist .content .project.horizontal:nth-child(1) h1:nth-child(1)::before {
    left: 0;
    width: 460px !important;
    height: 460px !important;
  }
  .projectlist .content .project .viewmore {
    min-width: 80px;
    text-align: center;
  }
  .projectlist .content .project.active.vertical .basics .cover {
    width: 0;
    -webkit-transition-delay: 0.4s;
            transition-delay: 0.4s;
  }
}
.projectdetails .header {
  width: 100%;
  height: 100vh;
  min-height: 800px;
}
.projectdetails .header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projectdetails .header #cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.projectdetails .header #description {
  position: absolute;
  top: 0;
  color: white;
  left: 50%;
  top: calc(50% - 20px);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.projectdetails .header #description h1 {
  font-size: 3rem;
  text-transform: uppercase;
  margin: 0;
}
.projectdetails .header #description h4 {
  text-align: center;
  font-family: "Source Sans Pro";
  font-weight: 100;
  margin: 0;
}

.projectdetailcontainer {
  background-color: white;
}

#projectdetailglass {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(17, 17, 17);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  z-index: 100;
  pointer-events: none;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.projectsectionhidden {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.projectsectionhidden.visible {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.loaded #projectdetailglass {
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  background-color: #111111;
  -webkit-animation: revealGlass 2.5s forwards;
          animation: revealGlass 2.5s forwards;
  -webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
          animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.loaded #projectdetailglass.black {
  background-color: #111111 !important;
}

@-webkit-keyframes revealGlass {
  0% {
    background-color: #111111;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}

@keyframes revealGlass {
  0% {
    background-color: #111111;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}
.section-line, #project-runottawa .social-assets .indicator, #project-runottawa .brand-process .indicator, #project-runottawa .role-screen .indicator,
#project-runottawa .selfie-filters .indicator,
#project-runottawa .final-logos .indicator, #project-runottawa .splash-screen .indicator,
#project-runottawa .home-screen .indicator, #project-runottawa .wireframe-box .indicator, #project-runottawa .indicatorcontainer .indicator, #project-rexel .photo1 .indicator,
#project-rexel .photo2 .indicator, #project-rexel .indicatorcontainer .indicator, #project-carrot .photo2 .indicator, #project-carrot .photo1 .indicator, #project-carrot .indicatorcontainer .indicator, #project-kfc .laptop .indicator, #project-kfc .wireframe .indicator, #project-kfc .indicatorcontainer .indicator, #project-auction .indicatorcontainer .indicator, #project-bdo .photo1 .indicator, #project-bdo .laptop .indicator, #project-bdo .indicatorcontainer .indicator, #project-tea .photo4 .indicator, #project-tea .photo3 .indicator, #project-tea .photo1 .indicator, #project-tea .fontface .indicator, #project-tea .indicatorcontainer .indicator, #project-kruger-afh .socialposts .indicator, #project-kruger-afh .infographic .indicator, #project-kruger-afh .copywriting .indicator, #project-kruger-afh .towelicons .indicator, #project-kruger-afh .gloomytowels .indicator, #project-kruger-afh .papertowels .indicator, #project-kruger .indicatorcontainer .indicator, #project-kruger .wireframe-box .indicator, #project-kruger .wireframe .indicator {
  position: absolute;
  left: 80px;
  top: 10%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 800;
  z-index: 1;
}
.section-line::before, #project-runottawa .social-assets .indicator::before, #project-runottawa .brand-process .indicator::before, #project-runottawa .role-screen .indicator::before,
#project-runottawa .selfie-filters .indicator::before,
#project-runottawa .final-logos .indicator::before, #project-runottawa .splash-screen .indicator::before,
#project-runottawa .home-screen .indicator::before, #project-runottawa .wireframe-box .indicator::before, #project-runottawa .indicatorcontainer .indicator::before, #project-rexel .photo1 .indicator::before,
#project-rexel .photo2 .indicator::before, #project-rexel .indicatorcontainer .indicator::before, #project-carrot .photo2 .indicator::before, #project-carrot .photo1 .indicator::before, #project-carrot .indicatorcontainer .indicator::before, #project-kfc .laptop .indicator::before, #project-kfc .wireframe .indicator::before, #project-kfc .indicatorcontainer .indicator::before, #project-auction .indicatorcontainer .indicator::before, #project-bdo .photo1 .indicator::before, #project-bdo .laptop .indicator::before, #project-bdo .indicatorcontainer .indicator::before, #project-tea .photo4 .indicator::before, #project-tea .photo3 .indicator::before, #project-tea .photo1 .indicator::before, #project-tea .fontface .indicator::before, #project-tea .indicatorcontainer .indicator::before, #project-kruger-afh .socialposts .indicator::before, #project-kruger-afh .infographic .indicator::before, #project-kruger-afh .copywriting .indicator::before, #project-kruger-afh .towelicons .indicator::before, #project-kruger-afh .gloomytowels .indicator::before, #project-kruger-afh .papertowels .indicator::before, #project-kruger .indicatorcontainer .indicator::before, #project-kruger .wireframe-box .indicator::before, #project-kruger .wireframe .indicator::before {
  content: "";
  position: absolute;
  right: calc(100% + 10px);
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 70px;
  height: 2px;
  background-color: black;
}
.section-line::after, #project-runottawa .social-assets .indicator::after, #project-runottawa .brand-process .indicator::after, #project-runottawa .role-screen .indicator::after,
#project-runottawa .selfie-filters .indicator::after,
#project-runottawa .final-logos .indicator::after, #project-runottawa .splash-screen .indicator::after,
#project-runottawa .home-screen .indicator::after, #project-runottawa .wireframe-box .indicator::after, #project-runottawa .indicatorcontainer .indicator::after, #project-rexel .photo1 .indicator::after,
#project-rexel .photo2 .indicator::after, #project-rexel .indicatorcontainer .indicator::after, #project-carrot .photo2 .indicator::after, #project-carrot .photo1 .indicator::after, #project-carrot .indicatorcontainer .indicator::after, #project-kfc .laptop .indicator::after, #project-kfc .wireframe .indicator::after, #project-kfc .indicatorcontainer .indicator::after, #project-auction .indicatorcontainer .indicator::after, #project-bdo .photo1 .indicator::after, #project-bdo .laptop .indicator::after, #project-bdo .indicatorcontainer .indicator::after, #project-tea .photo4 .indicator::after, #project-tea .photo3 .indicator::after, #project-tea .photo1 .indicator::after, #project-tea .fontface .indicator::after, #project-tea .indicatorcontainer .indicator::after, #project-kruger-afh .socialposts .indicator::after, #project-kruger-afh .infographic .indicator::after, #project-kruger-afh .copywriting .indicator::after, #project-kruger-afh .towelicons .indicator::after, #project-kruger-afh .gloomytowels .indicator::after, #project-kruger-afh .papertowels .indicator::after, #project-kruger .indicatorcontainer .indicator::after, #project-kruger .wireframe-box .indicator::after, #project-kruger .wireframe .indicator::after {
  content: "";
  position: absolute;
  left: 0;
  top: 55%;
  height: 10px;
  width: 95px;
  background-color: #e8bf2f;
  z-index: -1;
}

#projectclosebuttoncontainer h4 {
  font-weight: 300;
  font-size: 1rem;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(-140%, -50%);
          transform: translate(-140%, -50%);
  margin: 0;
  color: black;
}
#projectclosebuttoncontainer .projectclosebutton {
  position: fixed;
  top: 25px;
  right: 25px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.25rem;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  width: 45px;
  height: 45px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#projectclosebuttoncontainer .projectclosebutton.visible {
  opacity: 0.8;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
#projectclosebuttoncontainer .projectclosebutton svg {
  margin: auto;
  stroke-width: 2px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  pointer-events: none;
}
#projectclosebuttoncontainer .projectclosebutton:hover {
  opacity: 1;
}

#project-kruger .banner {
  width: 100%;
  height: 80vh;
  background-color: #3aab48;
  background-image: url(/static/media/1_landingpage.3449d6b5.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#project-kruger .banner-fr {
  background-image: url(/static/media/1_landingpage-fr.d8188ebd.png);
}
#project-kruger .services {
  width: 100%;
  height: calc(100vh - 80vh);
  background-color: #188d42;
  display: flex;
}
#project-kruger .services div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  max-width: 800px;
  color: white;
  margin: auto;
}
#project-kruger .services div h4 {
  font-weight: 400;
  text-align: center;
  margin: 10px;
  display: flex;
  align-items: center;
}
#project-kruger .description {
  padding: 80px 120px;
  margin-bottom: 40px;
}
#project-kruger .description img {
  max-width: 200px;
  display: block;
  margin: 0 auto;
}
#project-kruger .description p {
  max-width: 560px;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
}
#project-kruger .laptop {
  width: 100%;
  background-color: #dee0de;
}
#project-kruger .laptop img {
  width: 100%;
  object-fit: contain;
  display: block;
}
#project-kruger .palette {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
#project-kruger .palette .color {
  position: relative;
  padding-bottom: 100%;
}
#project-kruger .palette .color div {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 12%;
}
#project-kruger .palette .color div h4 {
  font-weight: 400;
  font-size: 0.6rem;
  color: white;
  margin: 0;
  margin-bottom: 0px;
}
#project-kruger .illustrations {
  background-color: #fdc500;
}
#project-kruger .illustrations .container {
  max-width: 800px;
  margin: auto;
  padding-top: 150px;
  padding-bottom: 150px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
#project-kruger .illustrations .container div {
  width: 100%;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
#project-kruger .illustrations .container div img {
  width: 100%;
}
#project-kruger .wireframe {
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  padding-top: 60px;
  position: relative;
}
#project-kruger .wireframe img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
#project-kruger .phone-mockup {
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  background-color: #f16450;
}
#project-kruger .phone-mockup img {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}
#project-kruger .tablet-mockup {
  height: 800px;
  background-color: #6fa2d6;
  display: flex;
  align-items: center;
}
#project-kruger .tablet-mockup .container {
  max-width: 800px;
  display: grid;
  grid-template-columns: 0.55fr 0.45fr;
  grid-column-gap: 25px;
  margin: auto;
}
#project-kruger .tablet-mockup .container img {
  width: 100%;
  display: flex;
  align-self: center;
  -webkit-filter: drop-shadow(5px 2px 14px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(5px 2px 14px rgba(0, 0, 0, 0.5));
}
#project-kruger .wireframe-box {
  height: 800px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
#project-kruger .wireframe-box img {
  width: 90%;
  margin: auto;
  max-height: 100%;
  object-fit: contain;
}
#project-kruger .products1 {
  width: 100%;
  display: grid;
}
#project-kruger .products1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
#project-kruger .products2 {
  min-height: 300px;
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;
}
#project-kruger .products2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#project-kruger .products3 {
  width: 100%;
  min-height: 500px;
  display: grid;
}
#project-kruger .products3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
#project-kruger .photoshoot {
  width: 100%;
  min-height: 90vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#project-kruger .photoshoot img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#project-kruger .productvideo {
  margin: 40px;
  margin-bottom: 0;
  padding-bottom: 40px;
}
#project-kruger .productvideo * {
  outline: none;
}
#project-kruger .indicatorcontainer {
  margin: 5% auto;
  position: relative;
}
#project-kruger .indicatorcontainer .indicator {
  position: relative;
}

#project-kruger-afh .banner {
  width: 100%;
  height: 650px;
  font-family: Poppins;
  font-size: 4rem;
  color: #e0ae25;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
}
#project-kruger-afh .banner h1 {
  margin: auto;
  z-index: 1;
  position: relative;
}
#project-kruger-afh .banner h1 span {
  color: white;
}
#project-kruger-afh .banner img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
}
#project-kruger-afh .services {
  width: 100%;
  min-height: 120px;
  background-color: #DFDFDF;
  display: flex;
}
#project-kruger-afh .services div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 800px;
  margin: auto;
}
#project-kruger-afh .services div h4 {
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  color: black;
  display: flex;
  align-items: center;
}
#project-kruger-afh .description {
  padding: 80px 120px;
  margin-bottom: 40px;
}
#project-kruger-afh .description img {
  max-width: 500px;
  display: block;
  margin: 0 auto;
}
#project-kruger-afh .description p {
  max-width: 560px;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
}
#project-kruger-afh .papertowels {
  position: relative;
}
#project-kruger-afh .papertowels img {
  width: 100%;
  display: block;
}
#project-kruger-afh .gloomytowels {
  position: relative;
}
#project-kruger-afh .gloomytowels .indicator {
  color: white;
}
#project-kruger-afh .gloomytowels .indicator::before {
  background-color: white;
}
#project-kruger-afh .gloomytowels img {
  width: 100%;
  display: block;
}
#project-kruger-afh .towelicons {
  position: relative;
  background-color: black;
  padding: 60px;
  padding-top: 120px;
  padding-bottom: 120px;
}
#project-kruger-afh .towelicons .indicator {
  color: white;
}
#project-kruger-afh .towelicons .indicator::before {
  background-color: white;
}
#project-kruger-afh .towelicons img {
  width: 80%;
  margin: auto;
  display: block;
}
#project-kruger-afh .copywriting {
  position: relative;
  background-color: black;
  padding: 60px;
  padding-top: 120px;
  padding-bottom: 120px;
}
#project-kruger-afh .copywriting .indicator {
  color: white;
}
#project-kruger-afh .copywriting .indicator::before {
  background-color: white;
}
#project-kruger-afh .copywriting img {
  width: 100%;
  margin: auto;
  display: block;
}
#project-kruger-afh .infographic {
  position: relative;
  background-color: black;
}
#project-kruger-afh .infographic .indicator {
  color: white;
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
}
#project-kruger-afh .infographic .indicator::before {
  background-color: white;
}
#project-kruger-afh .infographic img {
  width: 100%;
  margin: auto;
  display: block;
  pointer-events: none;
}
#project-kruger-afh .socialposts {
  position: relative;
}
#project-kruger-afh .socialposts img#tablet {
  width: 100%;
  display: block;
}
#project-kruger-afh .socialposts img#phone {
  position: absolute;
  bottom: 0%;
  right: 0px;
  width: 45%;
  height: auto;
}

#project-tea .banner {
  width: 100%;
  padding-top: 60px;
}
#project-tea .banner #logo1 {
  max-width: 300px;
  display: block;
  margin: auto;
}
#project-tea .banner #logo2 {
  display: block;
  width: 780px;
  max-width: 80%;
  margin: 80px auto;
}
#project-tea .banner p {
  width: 820px;
  max-width: 90%;
  margin: auto;
  margin-bottom: 80px;
  text-align: center;
}
#project-tea .services {
  width: 100%;
  background-color: black;
  display: flex;
}
#project-tea .services div {
  display: flex;
  max-width: 600px;
  width: 90%;
  color: white;
  margin: auto;
  padding: 40px 0;
}
#project-tea .services div h4 {
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 10px;
  display: grid;
  align-items: center;
  flex: 1 1;
  text-align: center;
}
#project-tea .indicatorcontainer {
  margin: 10% auto;
  position: relative;
}
#project-tea .indicatorcontainer .indicator {
  position: relative;
}
#project-tea .blackandwhite {
  width: 100%;
  height: auto;
}
#project-tea .blackandwhite img {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
}
#project-tea .colorgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 10px;
}
#project-tea .colorgrid .wallpaper {
  position: relative;
}
#project-tea .colorgrid .wallpaper::before {
  content: "";
  position: relative;
  top: 0;
  width: 100%;
  height: 80px;
  display: grid;
}
#project-tea .colorgrid .wallpaper:nth-child(1)::before {
  background-color: #d858a0;
}
#project-tea .colorgrid .wallpaper:nth-child(2)::before {
  background-color: #f7b61b;
}
#project-tea .colorgrid .wallpaper:nth-child(3)::before {
  background-color: #3ea448;
}
#project-tea .colorgrid .wallpaper:nth-child(4)::before {
  background-color: #8261a9;
}
#project-tea .colorgrid .wallpaper::after {
  content: "";
  position: absolute;
  color: white;
  top: 40px;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
#project-tea .colorgrid .wallpaper:nth-child(1)::after {
  content: "#D858A0";
}
#project-tea .colorgrid .wallpaper:nth-child(2)::after {
  content: "#F7B61B";
}
#project-tea .colorgrid .wallpaper:nth-child(3)::after {
  content: "#3EA448";
}
#project-tea .colorgrid .wallpaper:nth-child(4)::after {
  content: "#8261A9";
}
#project-tea .colorgrid .wallpaper img {
  width: 100%;
  max-height: 10vw;
  height: 180px;
  object-fit: cover;
  object-position: top;
  display: block;
}
#project-tea .fontface {
  width: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  position: relative;
}
#project-tea .fontface img {
  width: 80%;
  max-width: 600px;
  display: block;
  margin: auto;
  object-fit: contain;
}
#project-tea .photo1 {
  width: 100%;
  position: relative;
}
#project-tea .photo1 img {
  width: 100%;
  object-fit: contain;
}
#project-tea .photo2 {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
#project-tea .photo2 img {
  width: 100%;
  object-fit: contain;
}
#project-tea .photo3 {
  background-color: #efefef;
  padding: 100px;
  padding-top: 160px;
  position: relative;
}
#project-tea .photo3 div {
  max-width: 1000px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 100px;
}
#project-tea .photo3 div img {
  width: 100%;
  object-fit: contain;
}
#project-tea .photo4 {
  background-color: #f1f4bd;
  position: relative;
}
#project-tea .photo4 img {
  display: block;
  margin: auto;
  width: 100%;
  object-fit: cover;
}
#project-tea .photo4 .indicator::after {
  content: none;
}

#project-bdo .indicatorcontainer {
  margin: 40px auto;
  position: relative;
}
#project-bdo .indicatorcontainer .indicator {
  position: relative;
}
#project-bdo .banner {
  width: 100%;
}
#project-bdo .banner img {
  display: block;
  width: 100%;
  object-fit: contain;
}
#project-bdo .services {
  width: 100%;
  background-color: #223e81;
  display: flex;
}
#project-bdo .services div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 900px;
  width: 90%;
  color: white;
  margin: auto;
  padding: 40px 0;
}
#project-bdo .services div h4 {
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 10px 60px;
  display: grid;
  align-items: center;
  flex: 1 1;
  text-align: center;
}
#project-bdo .description {
  width: 80%;
  max-width: 600px;
  margin: auto;
  padding: 80px 0;
}
#project-bdo .description img {
  width: 100%;
  max-width: 120px;
  object-fit: contain;
  display: block;
  margin: auto;
  margin-top: 20px;
}
#project-bdo .description p {
  text-align: center;
  margin: 50px auto;
}
#project-bdo .laptop {
  width: 100%;
  background-color: #e8213b;
  position: relative;
}
#project-bdo .laptop img {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
}
#project-bdo .laptop .indicator {
  color: white;
}
#project-bdo .laptop .indicator::before {
  background-color: white;
  height: 1px;
}
#project-bdo .laptop .indicator::after {
  content: none;
}
#project-bdo .wireframe {
  width: 100%;
}
#project-bdo .wireframe img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
#project-bdo .photo1 {
  width: 100%;
  position: relative;
}
#project-bdo .photo1 img {
  width: 100%;
  object-fit: contain;
  display: block;
}
#project-bdo .photo2 {
  width: 100%;
}
#project-bdo .photo2 img {
  width: 100%;
  object-fit: contain;
  display: block;
}
#project-bdo .photo3 {
  width: 100%;
}
#project-bdo .photo3 img {
  width: 100%;
  object-fit: contain;
  display: block;
}
#project-bdo .productvideo2 .react-player__preview {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-color: #111111;
}

#project-auction .indicatorcontainer {
  margin: 10% auto;
  margin-bottom: 40px;
  position: relative;
}
#project-auction .indicatorcontainer .indicator {
  position: relative;
}
#project-auction .player-wrapper {
  position: relative;
  padding-top: 52.25%;
}
#project-auction .react-player {
  position: absolute;
  top: 0;
  left: 0;
}
#project-auction .banner {
  width: 100%;
}
#project-auction .banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
#project-auction .banner video {
  display: block;
  min-width: 100%;
}
#project-auction .services {
  width: 100%;
  height: auto;
  padding: 20px 0;
  background-color: #2cade3;
  display: flex;
}
#project-auction .services div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  max-width: 800px;
  color: white;
  margin: auto;
}
#project-auction .services div h4 {
  font-weight: 400;
  text-align: center;
  margin: 10px;
  display: flex;
  align-items: center;
}
#project-auction .description {
  padding: 80px 120px;
  margin-bottom: 40px;
}
#project-auction .description img {
  max-width: 200px;
  display: block;
  margin: 0 auto;
}
#project-auction .description #meritlogo {
  max-width: 500px;
  width: 200px;
  margin-bottom: 80px;
}
#project-auction .description p {
  max-width: 560px;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
}
#project-auction .description p span {
  color: #2cade3;
}
#project-auction .illustrations {
  width: 100%;
  margin-bottom: 80px;
}
#project-auction .illustrations .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  margin: auto;
}
#project-auction .illustrations .container img {
  width: 100%;
  height: 500px;
  object-fit: contain;
}
#project-auction .laptop,
#project-auction .tablet-mockup {
  width: 100%;
}
#project-auction .laptop img,
#project-auction .tablet-mockup img {
  width: 100%;
  display: block;
}

#project-kfc .indicatorcontainer {
  margin: 10% auto;
  margin-bottom: 40px;
  position: relative;
}
#project-kfc .indicatorcontainer .indicator {
  position: relative;
}
#project-kfc .banner {
  width: 100%;
  position: relative;
}
#project-kfc .banner #mainlogo {
  position: absolute;
  left: 50%;
  top: 20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 350px;
}
#project-kfc .banner .container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-end;
  min-height: 300px;
  padding-top: 80px;
}
#project-kfc .banner .container img {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
  object-position: bottom;
}
#project-kfc .banner .container img:nth-child(1) {
  height: 500px;
}
#project-kfc .banner .container img:nth-child(2) {
  height: auto;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
}
#project-kfc .banner .container img:nth-child(3) {
  height: auto;
  max-width: 70%;
  margin: 0 auto;
}
#project-kfc .description {
  padding: 80px 120px;
  margin-bottom: 40px;
  margin-bottom: 0;
  padding-bottom: 50px;
}
#project-kfc .description img {
  max-width: 150px;
  display: block;
  margin: 40px auto;
}
#project-kfc .description p {
  max-width: 560px;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
}
#project-kfc .services {
  width: 100%;
  padding: 30px 0;
  background-color: #111111;
  display: flex;
}
#project-kfc .services div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  max-width: 95%;
  color: white;
  margin: auto;
}
#project-kfc .services div h4 {
  font-weight: 600;
  text-align: center;
  margin: 10px;
  display: grid;
  align-items: center;
}
#project-kfc .picture {
  width: 100%;
}
#project-kfc .picture img {
  width: 100%;
}
#project-kfc .wireframe {
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  position: relative;
}
#project-kfc .wireframe img {
  width: 100%;
  max-width: 80%;
  margin: auto;
}
#project-kfc .laptop {
  width: 100%;
  background-color: #ee2c2a;
  position: relative;
}
#project-kfc .laptop img {
  width: 80%;
  display: block;
  margin: auto;
}
#project-kfc .laptop .indicator::after {
  content: none;
}
#project-kfc .tablets {
  width: 100%;
}
#project-kfc .tablets img {
  width: 100%;
  display: block;
  margin: auto;
}
#project-kfc .kfc-icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  max-width: 90%;
  margin: auto;
  margin-bottom: 80px;
  align-items: flex-end;
}
#project-kfc .kfc-icons img {
  width: 60%;
  object-fit: contain;
  margin: 0 auto;
}
#project-kfc .snapchat {
  width: 100%;
  background-image: url(/static/media/snapchat-background.6f519405.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
}
#project-kfc .snapchat img {
  display: block;
  margin: auto;
  max-width: 60%;
  width: 800px;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
#project-kfc .gallery-item {
  width: 100%;
  margin-top: 20px;
}
#project-kfc .gallery-item img {
  width: 100%;
  display: block;
}
#project-kfc .gallery-item.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#project-carrot .indicatorcontainer {
  margin: 40px auto;
  position: relative;
}
#project-carrot .indicatorcontainer .indicator {
  position: relative;
  display: block;
}
#project-carrot video {
  display: block;
}
#project-carrot .player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}
#project-carrot .react-player {
  position: absolute;
  top: 0;
  left: 0;
}
#project-carrot .banner {
  width: 100%;
}
#project-carrot .services {
  width: 100%;
  background-color: #07184f;
  display: flex;
}
#project-carrot .services div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  max-width: 95%;
  color: white;
  margin: auto;
  padding: 40px 0;
}
#project-carrot .services div h4 {
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  display: grid;
  align-items: center;
  flex: 1 1;
  text-align: center;
}
#project-carrot .description {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  padding: 80px 0;
}
#project-carrot .description p {
  text-align: center;
  margin: 50px auto;
}
#project-carrot .description p span {
  color: #fe9247;
}
#project-carrot .phone {
  width: 100%;
  background-color: #07184f;
}
#project-carrot .phone img {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
}
#project-carrot .photo1 {
  background-color: #f0f5f8;
  padding: 80px 0;
  position: relative;
}
#project-carrot .photo1 div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1000px;
  margin: auto;
  align-items: center;
}
#project-carrot .photo1 div img {
  width: 100%;
  object-fit: contain;
}
#project-carrot .photo1 div img:nth-child(2) {
  min-height: 400px;
}
#project-carrot .photo2 {
  width: 100%;
  position: relative;
}
#project-carrot .photo2 img {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
}
#project-carrot .photo2 .indicator {
  color: white;
}
#project-carrot .photo2 .indicator::before {
  background-color: white;
  height: 1px;
}
#project-carrot .photo2 .indicator::after {
  content: none;
}

#project-rexel .indicatorcontainer {
  margin-bottom: 40px;
  position: relative;
}
#project-rexel .indicatorcontainer .indicator {
  position: relative;
}
#project-rexel .player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}
#project-rexel .react-player {
  position: absolute;
  top: 0;
  left: 0;
}
#project-rexel .banner {
  width: 100%;
}
#project-rexel .banner video {
  display: block;
}
#project-rexel .services {
  width: 100%;
  background-color: #143d8d;
  display: flex;
}
#project-rexel .services div {
  display: flex;
  max-width: 800px;
  width: 90%;
  color: white;
  margin: auto;
  padding: 40px 0;
}
#project-rexel .services div h4 {
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 10px;
  display: grid;
  align-items: center;
  flex: 1 1;
  text-align: center;
}
#project-rexel .description {
  max-width: 800px;
  width: 90%;
  margin: auto;
  padding: 80px 0px;
}
#project-rexel .description img {
  max-width: 180px;
  display: block;
  margin: auto;
}
#project-rexel .description p {
  text-align: center;
  margin: 60px auto;
}
#project-rexel .photo1,
#project-rexel .photo2 {
  width: 100%;
  position: relative;
}
#project-rexel .photo1 img,
#project-rexel .photo2 img {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
}
#project-rexel .photo1 .indicatorcontainer {
  margin: 40px 0;
}
#project-rexel .photo2 .indicatorcontainer {
  margin-top: 40px;
}

#project-runottawa .indicatorcontainer {
  margin: 10% auto;
  position: relative;
}
#project-runottawa .indicatorcontainer .indicator {
  position: relative;
}
#project-runottawa .banner {
  width: 100%;
  height: 700px;
  background-image: url(/static/media/banner.b0352158.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
}
#project-runottawa .banner img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
  margin: auto;
  display: block;
  position: absolute;
  left: calc(50% - 40px);
  bottom: 180px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1;
  pointer-events: none;
}
#project-runottawa .banner h1 {
  font-size: 16vw;
  align-self: flex-end;
  margin: 10vw auto;
  color: white;
  text-align: center;
  line-height: 12.4vw;
}
@media screen and (min-width: 1100px) {
  #project-runottawa .banner img {
    bottom: 240px;
  }
}
@media screen and (max-width: 1070px) {
  #project-runottawa .bannner img {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50%;
  }
}
#project-runottawa .services {
  width: 100%;
  background-color: rgb(230, 230, 230);
  display: flex;
}
#project-runottawa .services div {
  display: flex;
  max-width: 800px;
  width: 90%;
  color: black;
  margin: auto;
  padding: 40px 0;
  flex-wrap: wrap;
}
#project-runottawa .services div h4 {
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 10px;
  display: grid;
  align-items: center;
  flex: 1 1;
  text-align: center;
}
#project-runottawa .description {
  padding: 60px;
}
#project-runottawa .description img {
  max-width: 100%;
  width: 400px;
  margin: 60px auto;
  display: block;
}
#project-runottawa .description p {
  max-width: 480px;
  margin: auto;
  margin-bottom: 60px;
  text-align: center;
  line-height: 20px;
}
#project-runottawa #palette {
  position: relative;
}
#project-runottawa #palette .indicatorcontainer {
  margin: 0;
  position: absolute;
  top: 20px;
  font-weight: 600;
}
#project-runottawa #palette #colors {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
#project-runottawa #palette #colors div {
  position: relative;
}
#project-runottawa #palette #colors div::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
#project-runottawa #palette #colors div span {
  position: absolute;
  right: 20px;
  bottom: 20px;
  color: white;
}
#project-runottawa #palette #colors div:nth-child(1) {
  background-color: #ec2890;
  background-image: url(/static/media/gradient-tile.e0024a21.jpg);
  background-size: cover;
  grid-row: 1/span 2;
  grid-column: 1/span 2;
}
#project-runottawa #palette #colors div:nth-child(2) {
  background-color: #ec2890;
}
#project-runottawa #palette #colors div:nth-child(3) {
  background-color: #c01e8d;
}
#project-runottawa #palette #colors div:nth-child(4) {
  background-color: #4b499d;
}
#project-runottawa #palette #colors div:nth-child(5) {
  background-color: #ffe300;
}
#project-runottawa #palette #colors div:nth-child(6) {
  background-color: #f4831f;
}
#project-runottawa #palette #colors div:nth-child(7) {
  background-color: #40b9eb;
}
#project-runottawa .wireframe-box {
  width: 100%;
  height: 800px;
  display: flex;
  align-items: center;
  padding-top: 60px;
  position: relative;
}
#project-runottawa .wireframe-box img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}
#project-runottawa .splash-screen,
#project-runottawa .home-screen {
  position: relative;
  background-image: url(/static/media/splash-background.bb712389.jpg);
  background-size: cover;
}
#project-runottawa .splash-screen img,
#project-runottawa .home-screen img {
  max-width: 100%;
  margin: auto;
  display: block;
  padding: 100px 0;
}
#project-runottawa .role-screen,
#project-runottawa .selfie-filters,
#project-runottawa .final-logos {
  position: relative;
}
#project-runottawa .role-screen img,
#project-runottawa .selfie-filters img,
#project-runottawa .final-logos img {
  max-width: 100%;
  margin: auto;
  display: block;
  padding: 100px 0;
}
#project-runottawa .champ {
  background-image: url(/static/media/08_BG.71f0f6fd.png);
  background-size: cover;
  padding: 40px;
}
#project-runottawa .champ img {
  max-width: 100%;
}
#project-runottawa .brand-process {
  position: relative;
}
#project-runottawa .brand-process img {
  max-width: 100%;
  margin: auto;
  display: block;
  padding: 100px 0;
}
#project-runottawa .brand-palette {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
#project-runottawa .brand-palette div {
  position: relative;
}
#project-runottawa .brand-palette div::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
#project-runottawa .brand-palette div span {
  position: absolute;
  right: 15px;
  bottom: 20px;
  color: white;
  font-size: 0.8rem;
}
#project-runottawa .brand-palette div:nth-child(1) {
  background-color: #ff0000;
}
#project-runottawa .brand-palette div:nth-child(2) {
  background-color: #f38ba0;
}
#project-runottawa .brand-palette div:nth-child(3) {
  background-color: #f8e400;
}
#project-runottawa .brand-palette div:nth-child(4) {
  background-color: #b9e4db;
}
#project-runottawa .brand-palette div:nth-child(5) {
  background-color: #1b4d4e;
}
#project-runottawa .brand-palette div:nth-child(6) {
  background-color: #1d3847;
}
#project-runottawa #brand-palette-images {
  width: 100%;
}
#project-runottawa .social-assets {
  position: relative;
  background-color: #1d3847;
  width: 100vw;
  overflow: hidden;
}
#project-runottawa .social-assets .indicator {
  z-index: 10;
  top: 80px;
}
#project-runottawa .social-assets .indicator::after {
  display: none;
}
#project-runottawa .social-assets .color-container {
  width: 100%;
  position: relative;
}
#project-runottawa .social-assets .color-container img {
  width: 100%;
  display: block;
  position: relative;
}
#project-runottawa .social-assets .color-container:nth-child(2) .bg {
  z-index: 3;
}
#project-runottawa .social-assets .color-container:nth-child(3) .bg {
  z-index: 2;
}
#project-runottawa .social-assets .color-container:nth-child(4) .bg {
  z-index: 1;
}
#project-runottawa .social-assets .color-container:nth-child(3), #project-runottawa .social-assets .color-container:nth-child(4) {
  margin-top: -60vw;
}
#project-runottawa .social-assets .color-container .phone1,
#project-runottawa .social-assets .color-container .phone2,
#project-runottawa .social-assets .color-container .phone3 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10;
}
#project-runottawa .social-assets .color-container .phone1 {
  -webkit-transform: translate(-50%, -50%) translateY(-10%) scale(0.9);
          transform: translate(-50%, -50%) translateY(-10%) scale(0.9);
}
#project-runottawa .social-assets .color-container .phone2 {
  -webkit-transform: translate(-50%, -50%) translateY(-20%);
          transform: translate(-50%, -50%) translateY(-20%);
}
#project-runottawa .social-assets .color-container .phone3 {
  -webkit-transform: translate(-50%, -50%) translateY(-35%) scale(1.2) rotate(-8deg);
          transform: translate(-50%, -50%) translateY(-35%) scale(1.2) rotate(-8deg);
}
#project-runottawa .social-assets .phone4 {
  width: 100%;
  margin-top: -50%;
  z-index: 5;
  position: relative;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

@media screen and (min-width: 1200px) {
  #project-kruger .products1 {
    min-height: 800px;
  }
  #project-kruger .products2 {
    min-height: 380px;
  }
  #project-kruger .products3 {
    min-height: 800px;
  }
}
@media screen and (min-width: 1400px) {
  #project-kfc .wireframe img {
    max-height: 100%;
    object-fit: contain;
  }
}
@media screen and (max-width: 1070px) {
  #project-auction .services div {
    grid-template-columns: 1fr;
  }
  #project-auction .services div h4 {
    display: block;
  }
  #project-bdo .services div {
    width: 100%;
  }
  #project-bdo .services div h4 {
    margin: 10px;
  }
  #project-carrot .services div {
    grid-template-columns: 1fr;
  }
  #project-runottawa .banner {
    height: 600px;
  }
  #project-runottawa #palette .indicatorcontainer {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
  #project-runottawa #palette #colors {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  #project-runottawa #palette #colors div:nth-child(1) {
    grid-row: 1/span 2;
    grid-column: 1/span 1;
  }
  #project-runottawa .brand-palette {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 740px) {
  .projectclosebutton {
    opacity: 0.8;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  #project-kruger .description {
    padding: 80px 20px;
  }
  #project-kruger .services div {
    grid-template-columns: 1fr 1fr;
  }
  #project-kruger .services div h4 {
    margin: 10px auto;
  }
  #project-kruger .palette {
    grid-template-columns: 1fr 1fr 1fr;
  }
  #project-kruger .illustrations .container {
    grid-template-columns: 1fr 1fr;
  }
  #project-kruger .wireframe {
    height: 500px;
  }
  #project-kruger .tablet-mockup {
    height: auto;
  }
  #project-kruger .tablet-mockup .container {
    grid-template-columns: 1fr;
    padding: 20%;
  }
  #project-kruger .products1 {
    height: unset;
  }
  #project-kruger .products1 img {
    height: unset;
    min-height: unset;
  }
  #project-kruger .products2 {
    grid-template-columns: 1fr;
  }
  #project-kruger .products2 img {
    height: 400px;
  }
  #project-kruger .products3 {
    min-height: unset;
  }
  #project-kruger .products3 img {
    height: 400px;
  }
  #project-kruger .photoshoot {
    min-height: unset;
  }
  #project-kruger .photoshoot img {
    height: auto;
  }
  #project-auction .illustrations .container {
    grid-template-columns: 1fr;
  }
  #project-auction .description {
    padding: 80px 40px;
  }
  #project-kfc .banner .container {
    grid-template-columns: 1fr 1fr;
  }
  #project-kfc .banner .container img:nth-child(3) {
    display: none;
  }
  #project-kfc .services div {
    grid-template-columns: 1fr;
  }
  #project-kfc .wireframe {
    height: unset;
    padding: 100px 0;
  }
  #project-kfc .description {
    padding: 80px 40px;
  }
  #project-bdo .services div {
    grid-template-columns: 1fr;
  }
  #project-tea .colorgrid {
    grid-template-columns: 1fr;
  }
  #project-tea .colorgrid .wallpaper img {
    max-height: 100px;
  }
  #project-tea .photo1 .indicator {
    -webkit-transform: translateY(-80px);
            transform: translateY(-80px);
  }
  #project-tea .photo3 div {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
  #project-kruger-afh .banner h1 {
    font-size: 5rem;
  }
  #project-kruger-afh .description {
    padding: 80px 40px;
  }
  #project-kruger-afh .description img {
    max-width: 90%;
  }
  #project-kruger-afh .towelicons img {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .projectclosebutton {
    top: 15px;
    right: 15px;
    background-color: transparent;
    box-shadow: none;
  }
  #project-kruger .services {
    height: unset;
    padding: 20px 0;
  }
  #project-kruger .services div {
    grid-template-columns: 1fr;
  }
  #project-kruger .palette {
    grid-template-columns: 1fr 1fr 1fr;
  }
  #project-kruger .phone-mockup {
    height: 600px;
  }
  #project-kruger .wireframe-box {
    height: 500px;
  }
  #project-kfc .banner .container {
    grid-template-columns: 1fr;
    padding-top: 160px;
  }
  #project-kfc .banner .container img:nth-child(2) {
    max-width: 350px;
    margin-top: 60px;
  }
  #project-kfc .wireframe img {
    max-width: 100%;
  }
  #project-rexel .services div {
    flex-direction: column;
  }
  #project-carrot .photo1 div {
    grid-template-columns: 1fr;
  }
  #project-carrot .photo1 div img {
    width: 70%;
    margin: auto;
  }
  #project-carrot .photo1 div img:first-child {
    margin-top: 80px;
  }
  .services div h4 {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
  .indicator {
    font-size: 0.8rem;
  }
}
.description-content-container, .teamcontainer .descriptioncontainer .photos .item .descriptioncontainer, .teamcontainer .descriptioncontainer .descriptions {
  text-align: right;
  margin: 0 20px;
  padding-left: 20px;
  position: relative;
}
.description-content-container h2, .teamcontainer .descriptioncontainer .photos .item .descriptioncontainer h2, .teamcontainer .descriptioncontainer .descriptions h2 {
  position: relative;
  background-color: #e8bf2f;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 20px;
  margin-left: auto;
  font-size: 14px;
  text-transform: uppercase;
  font-family: Poppins;
  font-weight: 600;
  color: #111111;
}
.description-content-container h2::before, .teamcontainer .descriptioncontainer .photos .item .descriptioncontainer h2::before, .teamcontainer .descriptioncontainer .descriptions h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #e8bf2f;
  -webkit-transition: width 0.25s ease;
  transition: width 0.25s ease;
}
.description-content-container h1, .teamcontainer .descriptioncontainer .photos .item .descriptioncontainer h1, .teamcontainer .descriptioncontainer .descriptions h1 {
  margin: 20px 0;
  font-family: "Poppins";
  font-size: 1.8rem;
  font-weight: 600;
}
.description-content-container .summary, .teamcontainer .descriptioncontainer .photos .item .descriptioncontainer .summary, .teamcontainer .descriptioncontainer .descriptions .summary {
  margin: 20px 0;
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: 100;
  color: white;
}
.description-content-container .readmore, .teamcontainer .descriptioncontainer .photos .item .descriptioncontainer .readmore, .teamcontainer .descriptioncontainer .descriptions .readmore {
  font-family: ProximaNova;
}

.teamcontainer {
  color: white;
  background-color: #111111;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.teamcontainer * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.teamcontainer .navigation {
  position: absolute;
  top: 50%;
  left: 6%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 150px;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.2);
}
.teamcontainer .navigation #progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0%;
  background-color: rgba(255, 255, 255, 0.8);
}
.teamcontainer .navigation h4 {
  position: absolute;
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
}
.teamcontainer .navigation h4:nth-child(1) {
  top: 0;
  -webkit-transform: translate(-50%, -160%);
          transform: translate(-50%, -160%);
}
.teamcontainer .navigation h4:nth-child(2) {
  bottom: 0;
  -webkit-transform: translate(-50%, 160%);
          transform: translate(-50%, 160%);
}
.teamcontainer .navigation #chevrons {
  height: 100%;
  position: absolute;
}
.teamcontainer .navigation #chevrons div {
  position: absolute;
  opacity: 0.25;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.teamcontainer .navigation #chevrons div:active {
  opacity: 0.5;
  -webkit-transition: opacity 0s ease;
  transition: opacity 0s ease;
}
.teamcontainer .navigation #chevrons div:hover {
  opacity: 0.6;
}
.teamcontainer .navigation #chevrons div:nth-child(1) {
  top: -40px;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
}
.teamcontainer .navigation #chevrons div:nth-child(2) {
  bottom: -45px;
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%);
}
.teamcontainer #mobile-humans-navigator {
  position: absolute;
  top: 18%;
  right: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  display: none;
}
.teamcontainer #mobile-humans-navigator #mobileaboutthishuman {
  border-bottom: 2px solid;
  font-size: 0.75rem;
  outline: none;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.teamcontainer #mobile-humans-navigator #mobilenexthuman {
  margin-left: 10px;
  display: flex;
}
.teamcontainer #mobile-humans-navigator #mobilenexthuman div:active {
  opacity: 0.6;
}
.teamcontainer .descriptioncontainer {
  position: absolute;
  top: calc(50vh - 200px);
  display: flex;
  width: 50vw;
  max-width: 380px;
  left: 50%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  padding-right: 20px;
}
.teamcontainer .descriptioncontainer * {
  color: white;
}
.teamcontainer .descriptioncontainer .descriptions {
  margin-left: auto;
}
.teamcontainer .descriptioncontainer .descriptions .summary, .teamcontainer .descriptioncontainer .descriptions h1, .teamcontainer .descriptioncontainer .descriptions h2 {
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
}
.teamcontainer .descriptioncontainer .photos {
  width: 50vw;
  height: 100vh;
  -webkit-transform-origin: top;
          transform-origin: top;
}
.teamcontainer .descriptioncontainer .photos .item {
  height: 100vh;
  width: 50vw;
  position: relative;
}
.teamcontainer .descriptioncontainer .photos .item img {
  width: 320px;
  height: 320px;
  object-fit: cover;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.teamcontainer .grid-toggler {
  position: absolute;
  bottom: 60px;
  left: calc(50vw - 40px);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  display: flex;
  opacity: 0;
}
.teamcontainer .grid-toggler span {
  width: 80px;
  text-align: right;
  margin-right: 10px;
  margin-top: 10px;
  opacity: 0.25;
  line-height: 20px;
}
.teamcontainer .grid-toggler .tagline {
  font-family: HelveticaNeueThin;
  font-size: 10px;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.teamcontainer .grid-toggler .tagline.active div {
  background-color: rgba(255, 255, 255, 0.2);
}
.teamcontainer .grid-toggler .tagline div {
  margin: 2px;
  background-color: #e8bf2f;
}
.teamcontainer .grid-toggler .tagline div.active {
  background-color: #e8bf2f;
}
.teamcontainer .portraits {
  position: absolute;
  left: 50vw;
  width: calc(50vw - 0vh/2);
  padding: 0;
  height: calc(100vh - 0vh );
  top: calc(0vh / 2);
  background-color: white;
  overflow: hidden;
  opacity: 0;
}
.teamcontainer .portraits .photos {
  top: 0;
  background-color: #111111;
  width: 50vw;
  height: 100vh;
}
.teamcontainer .portraits .photos::-webkit-scrollbar {
  display: none;
}
.teamcontainer .portraits .photos .item {
  height: 100%;
  width: 100%;
}
.teamcontainer .portraits .photos .item .descriptioncontainer {
  display: none;
  left: 0px;
  top: 0;
  width: 100%;
  pointer-events: none;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.teamcontainer .portraits .photos .item .descriptioncontainer * {
  height: auto;
}
.teamcontainer .portraits .photos .item .descriptioncontainer .descriptions {
  top: 100%;
  -webkit-transform: translateY(-120%);
          transform: translateY(-120%);
}
.teamcontainer .portraits .photos .item:hover .descriptioncontainer {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.teamcontainer .portraits img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.teamcontainer .portraits .cover {
  position: absolute;
  width: 140%;
  height: 140%;
  left: -10%;
  top: -10%;
  z-index: 1;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  -webkit-transition-property: background-color, -webkit-backdrop-filter;
  transition-property: background-color, -webkit-backdrop-filter;
  transition-property: backdrop-filter, background-color;
  transition-property: backdrop-filter, background-color, -webkit-backdrop-filter;
}
.teamcontainer .portraits .cover.reveal {
  -webkit-backdrop-filter: blur(0px);
          backdrop-filter: blur(0px);
  background-color: rgba(16, 16, 16, 0);
}
.teamcontainer .portraits .teamgrid {
  height: 100vh;
  overflow: scroll;
  background-color: black;
}
.teamcontainer .portraits .teamgrid .scroll-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.teamcontainer .portraits .teamgrid .scrollbar-track {
  display: none !important;
}
.teamcontainer .portraits .teamgrid .item {
  display: flex;
  opacity: 1;
  position: relative;
  -webkit-transition: -webkit-filter 0.1s ease-in-out;
  transition: -webkit-filter 0.1s ease-in-out;
  transition: filter 0.1s ease-in-out;
  transition: filter 0.1s ease-in-out, -webkit-filter 0.1s ease-in-out;
}
.teamcontainer .portraits .teamgrid .item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
.teamcontainer .portraits .teamgrid .item:hover::before {
  opacity: 0;
}
.teamcontainer .portraits .teamgrid .item.active::before {
  opacity: 0;
}
.teamcontainer .portraits .teamgrid .item .details {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  padding: 5%;
}
.teamcontainer .portraits .teamgrid .item .details .descriptions {
  text-align: right;
  margin: 0 20px;
  position: relative;
}
.teamcontainer .portraits .teamgrid .item .details .descriptions h2 {
  position: relative;
  background-color: #e8bf2f;
  display: table;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 20px;
  margin-left: auto;
  font-size: 14px;
  text-transform: uppercase;
  font-family: Poppins;
  font-weight: 500;
  color: #111111;
}
.teamcontainer .portraits .teamgrid .item .details .descriptions h2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #e8bf2f;
  -webkit-transition: width 0.25s ease;
  transition: width 0.25s ease;
}
.teamcontainer .portraits .teamgrid .item .details .descriptions h1 {
  margin: 20px 0;
  font-family: "Poppins";
  font-size: 1.8rem;
  font-weight: 600;
}
.teamcontainer .portraits .teamgrid .item .details .descriptions .summary {
  margin: 20px 0;
  font-family: ProximaNova;
  font-size: 1rem;
  color: white;
}
.teamcontainer .slick-track {
  left: 0;
  position: relative;
  display: flex;
}
.teamcontainer .slick-track * {
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.teamcontainer .slick-track .slick-slide {
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  opacity: 0;
}
.teamcontainer .slick-track img {
  height: 100vh !important;
}
.teamcontainer .photonavigation {
  position: absolute;
  top: calc(calc(50vh - 200px) + 40px);
  right: 40px;
  height: calc(320px - 80px);
  width: 10px;
  padding-left: 3px;
}
.teamcontainer .photonavigation .bar1 {
  background-color: rgba(152, 152, 152, 0.1294117647);
  height: 100%;
  width: 2px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.teamcontainer .photonavigation .bar1 .bar2 {
  position: absolute;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 2px;
  height: 20%;
  background-color: #e8bf2f;
  -webkit-transition: top 0.4s ease;
  transition: top 0.4s ease;
}
.teamcontainer .photonavigation .personindex, .teamcontainer .photonavigation .personindexall {
  position: absolute;
  font-family: HelveticaNeueLight;
  color: #111111;
}
.teamcontainer .photonavigation .personindexall {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.teamcontainer .photonavigation .personindex {
  top: -18%;
  display: flex;
  overflow: hidden;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  letter-spacing: 1px;
}
.teamcontainer .photonavigation .personindex span {
  display: block;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.teamcontainer .photonavigation .personindex span:nth-child(1) {
  margin-right: 1px;
}
.teamcontainer .photonavigation .personindex span:nth-child(2) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.teamcontainer .photonavigation .personindex.hide span {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  opacity: 0.5;
}
.teamcontainer .photonavigation .personindex.show span {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.teamcontainer .photonavigation .personindexall {
  bottom: -18%;
}

@media screen and (max-width: 1070px) {
  .teamcontainer {
    min-height: 100vh;
  }
  .teamcontainer .descriptioncontainer {
    z-index: 1;
  }
  .teamcontainer .descriptioncontainer .descriptions {
    padding-left: 40px;
  }
  .teamcontainer .portraits {
    width: 65%;
    height: 80vh;
    max-height: 600px;
    position: absolute;
    right: 0;
    left: unset;
    top: 50%;
    -webkit-transform: translateY(-45%);
            transform: translateY(-45%);
  }
  .teamcontainer .portraits .photos {
    overflow: visible;
    width: 100%;
    height: 100%;
  }
  .teamcontainer .portraits .photos * {
    height: 100%;
  }
  .teamcontainer .portraits .teamgrid {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
  .teamcontainer .portraits .teamgrid .item:hover .details {
    opacity: 1;
  }
  .teamcontainer .slick-list {
    padding-left: 20%;
    padding-right: 10px;
  }
  .teamcontainer .slick-list .slick-track {
    height: 100% !important;
    width: 100%;
    object-fit: contain;
  }
  .teamcontainer .slick-list .slick-track .item {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    border-radius: 5px;
    overflow: hidden;
    opacity: 0.2;
    -webkit-transition: opacity 0.4s ease;
    transition: opacity 0.4s ease;
  }
  .teamcontainer .slick-list .slick-track .slick-active .item {
    opacity: 1;
    -webkit-transition: opacity 0.4s ease;
    transition: opacity 0.4s ease;
  }
  .teamcontainer .slick-list .slick-track * {
    height: 100%;
  }
  .teamcontainer .slick-list .slick-track img {
    height: 100% !important;
  }
  .teamcontainer .grid-toggler {
    display: none;
  }
  .teamcontainer .navigation {
    -webkit-transform: rotate(45deg) translateY(50%);
            transform: rotate(45deg) translateY(50%);
    left: 17.5%;
    top: 80%;
    height: 50px;
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }
  .teamcontainer .navigation h4:nth-child(1) {
    top: 100%;
    -webkit-transform: translate(-180%, -140%) rotate(-45deg);
            transform: translate(-180%, -140%) rotate(-45deg);
  }
  .teamcontainer .navigation h4:nth-child(2) {
    bottom: 100%;
    -webkit-transform: translate(140%, 140%) rotate(-45deg);
            transform: translate(140%, 140%) rotate(-45deg);
  }
}
@media screen and (max-width: 740px) {
  .teamcontainer .descriptioncontainer {
    top: 30%;
  }
  .teamcontainer .portraits {
    height: 60vh;
  }
}
@media screen and (max-width: 600px) {
  .teamcontainer .navigation {
    left: 60px;
    top: calc(100% - 110px);
    display: none;
  }
  .teamcontainer .navigation #chevrons {
    display: none;
  }
  .teamcontainer #mobile-humans-navigator {
    display: grid;
  }
  .teamcontainer #mobile-humans-navigator #mobileaboutthishuman {
    display: block;
  }
  .teamcontainer .descriptioncontainer {
    display: none;
  }
  .teamcontainer .slick-list {
    padding-left: 10%;
    padding-right: 10%;
  }
  .teamcontainer .portraits {
    width: 100%;
    height: 65vh;
    min-height: 320px;
  }
  .teamcontainer .portraits .photos .item {
    opacity: 1;
    pointer-events: none;
  }
  .teamcontainer .portraits .photos .item .descriptioncontainer {
    display: block;
  }
  .teamcontainer .portraits .photos .item img:hover {
    opacity: 0.6;
  }
}
@media screen and (max-height: 600px) {
  .teamcontainer .navigation {
    display: none !important;
  }
}
.careerscontainer {
  padding-top: 16vh;
  color: white;
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;
  opacity: 0;
}
.careerscontainer a {
  text-decoration: none;
  display: table;
}
.careerscontainer h1 {
  font-size: 3rem;
  margin: 10%;
  margin-left: 20%;
}
.careerscontainer #careerscontent p {
  padding-top: 40px;
  padding-right: 15%;
  font-size: 1rem;
  line-height: 2rem;
  max-width: 600px;
}
.careerscontainer #careerscontent #postings {
  margin-top: 100px;
  padding-bottom: 60px;
}
.careerscontainer #careerscontent #postings .posting {
  margin-bottom: 20px;
}
.careerscontainer #careerscontent #postings h3 {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
}
.careerscontainer #careerscontent #postings h4 {
  font-size: 0.8rem;
  font-weight: 400;
}
.careerscontainer #careerscontent #postings .details {
  padding: 10px 35px;
  background-color: #e8bf2f;
  color: #111111;
  display: table;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
.careerscontainer #careerscontent #postings .details:hover {
  color: white;
}

@media screen and (max-width: 1000px) {
  .careerscontainer {
    grid-template-columns: 1fr;
    padding-top: 100px;
  }
  .careerscontainer h1 {
    text-align: center;
    margin: 60px auto;
  }
  .careerscontainer #careerscontent {
    padding: 0 10%;
  }
  .careerscontainer #careerscontent a {
    margin: auto;
  }
  .careerscontainer #careerscontent p {
    padding-top: 0;
    padding-right: 0;
    padding: 0 10%;
    text-align: center;
  }
  .careerscontainer #careerscontent #postings {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .careerscontainer #careerscontent #postings .posting h3 {
    text-align: center;
  }
  .careerscontainer #careerscontent #postings .posting h4 {
    text-align: center;
  }
  .careerscontainer #careerscontent #postings .posting .details {
    margin: auto;
  }
}
.contactcontainer {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #111111;
}
.contactcontainer #social {
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  opacity: 0;
}
.contactcontainer #social div {
  margin: 5px 8px;
  opacity: 0.2;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
}
.contactcontainer #social div:hover {
  opacity: 0.5;
}
.contactcontainer #social div svg,
.contactcontainer #social div img {
  width: 25px;
  height: 25px;
  pointer-events: none;
  display: block;
}
.contactcontainer #social div #instagram {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
.contactcontainer .container {
  height: 100%;
  color: white;
}
.contactcontainer .container .mapcover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 1;
  background-color: rgba(5, 5, 5, 0.5);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  pointer-events: none;
}
.contactcontainer .container .map {
  position: absolute;
  width: 40%;
  height: 100%;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.contactcontainer .container .map .gm-fullscreen-control,
.contactcontainer .container .map .gmnoprint,
.contactcontainer .container .map .gm-style-cc,
.contactcontainer .container .map a[rel=noopener] img {
  opacity: 0.05;
}
.contactcontainer .container .logo {
  width: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 50px;
  margin-left: auto;
  position: relative;
  z-index: 2;
}
.contactcontainer .container .logo img {
  width: 200px;
}
.contactcontainer .container .form {
  position: absolute;
  top: 50%;
  left: 30%;
  -webkit-transform: translate(-50%, -80%);
          transform: translate(-50%, -80%);
  z-index: 2;
  width: 50%;
  margin-left: 0;
  text-align: left;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.contactcontainer .container .form * {
  z-index: 2;
}
.contactcontainer .container .form h1, .contactcontainer .container .form h5, .contactcontainer .container .form .inputs {
  opacity: 0;
}
.contactcontainer .container .form h1, .contactcontainer .container .form h5 {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
}
.contactcontainer .container .form h1 {
  word-break: break-word;
  font-family: "Poppins";
  font-weight: 200;
  font-size: 2rem;
  margin-bottom: 0px;
}
.contactcontainer .container .form h5 {
  margin-bottom: 20px;
  word-break: break-word;
  margin: 10px 0;
  color: rgba(255, 255, 255, 0.8);
  font-family: Source Sans Pro;
  font-weight: 100;
  font-size: 1rem;
}
.contactcontainer .container .form .inputs {
  position: relative;
  height: 50px;
  width: 0%;
  max-width: 100%;
}
.contactcontainer .container .form .inputs input,
.contactcontainer .container .form .inputs textarea {
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid white;
  padding: 10px 0;
  width: 100%;
  caret-color: rgba(232, 191, 47, 0.75);
  color: white;
  font-family: "Poppins";
  font-weight: 100;
  font-size: 1.1rem;
  position: absolute;
}
.contactcontainer .container .form .buttons,
.contactcontainer .container .form .errors {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-top: 10px;
  position: absolute;
  right: 0;
  bottom: unset;
  top: 50%;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.contactcontainer .container .form .buttons .send,
.contactcontainer .container .form .errors .send {
  font-size: 20px;
  position: relative;
  cursor: pointer;
}
.contactcontainer .container .form .buttons div,
.contactcontainer .container .form .errors div {
  display: flex;
}
.contactcontainer .container .form .buttons div div,
.contactcontainer .container .form .errors div div {
  padding: 5px;
  font-family: "Source Sans Pro";
  position: relative;
  cursor: pointer;
}
.contactcontainer .container .form .buttons div .send,
.contactcontainer .container .form .errors div .send {
  font-size: 20px;
  position: relative;
  cursor: pointer;
}
.contactcontainer .container .form .buttons.buttons,
.contactcontainer .container .form .errors.buttons {
  opacity: 0;
  -webkit-transform: translate(-10px, 100%);
          transform: translate(-10px, 100%);
}
.contactcontainer .container .form .buttons.buttons #left img,
.contactcontainer .container .form .errors.buttons #left img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.contactcontainer .container .form .buttons.buttons #left .line,
.contactcontainer .container .form .errors.buttons #left .line {
  -webkit-transform: translateX(-4px);
          transform: translateX(-4px);
}
.contactcontainer .container .form .buttons.buttons #right .line,
.contactcontainer .container .form .errors.buttons #right .line {
  -webkit-transform: translateX(4px);
          transform: translateX(4px);
}
.contactcontainer .container .form .buttons.buttons .line,
.contactcontainer .container .form .errors.buttons .line {
  height: 1px;
  width: 40px;
  background-color: white;
  padding: 0;
  margin: auto;
}
.contactcontainer .container .form .errors {
  margin-left: 0;
  left: 0;
  font-family: BrandonGrotesqueMediumItalic;
  font-size: 14px;
  color: #cf2e2e;
}
.contactcontainer .container .form .errors h4 {
  padding-top: 5px;
}
.contactcontainer .container .form .leaf-canvas {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: none;
}
.contactcontainer .container .form .leaf-canvas canvas {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px);
}
.contactcontainer .container .info {
  position: absolute;
  bottom: 0;
  left: 20%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 30%;
  padding: 40px;
  font-size: 12px;
  font-weight: 100;
  font-family: "Poppins";
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: left;
}
.contactcontainer .container .info:last-child {
  margin-bottom: 0;
}
.contactcontainer .container .info h3, .contactcontainer .container .info a {
  color: rgba(152, 152, 152, 0.6);
  text-decoration: none;
  font-size: 0.8rem;
  margin: 5px 0;
  font-family: Poppins;
  font-weight: 400;
}
.contactcontainer .container .info #cities {
  display: flex;
  opacity: 0.8;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.contactcontainer .container .info #cities h2 {
  font-size: 1rem;
  font-weight: 400;
  margin-right: 1rem;
  position: relative;
  cursor: default;
}
.contactcontainer .container .info #cities h2::before {
  content: "";
  position: absolute;
  bottom: 0px;
  height: 6px;
  width: 0;
  background-color: #e8bf2f;
  z-index: -1;
  -webkit-transition: width 0.2s ease;
  transition: width 0.2s ease;
}
.contactcontainer .container .info #cities h2.active::before {
  width: 100%;
  -webkit-transition: width 0.2s ease 0.1s;
  transition: width 0.2s ease 0.1s;
}
.contactcontainer .container .info #cities, .contactcontainer .container .info #details h3 {
  opacity: 0;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  cursor: pointer;
}
.contactcontainer .container .info #cities.active a, .contactcontainer .container .info #details h3.active a {
  color: rgb(152, 152, 152);
}
.contactcontainer .container .info #cities.two a:hover, .contactcontainer .container .info #details h3.two a:hover {
  color: rgb(152, 152, 152);
}
.contactcontainer .container .info #terms-and-privacy {
  margin-top: 20px;
}

@media screen and (min-width: 1200px) {
  .contactcontainer .container .map {
    width: 50%;
  }
  .contactcontainer .container .form {
    width: 35%;
    left: 10%;
    -webkit-transform: translateY(-80%);
            transform: translateY(-80%);
  }
  .contactcontainer .container .form .leaf-canvas {
    display: none !important;
  }
  .contactcontainer .container .info {
    width: 25%;
    margin-left: 2.5%;
  }
}
@media screen and (max-width: 1070px) {
  .contactcontainer .container .map {
    height: 400px;
    width: 20%;
  }
  .contactcontainer .container .form {
    left: 10%;
    -webkit-transform: translate(0, -80%);
            transform: translate(0, -80%);
  }
  .contactcontainer .container .info:last-child {
    width: 80%;
    -webkit-transform: none;
            transform: none;
    padding: 0;
    left: 10%;
    bottom: 40px;
  }
}
@media screen and (max-width: 740px) {
  .contactcontainer .container .map {
    width: 0;
  }
  .contactcontainer .container .form {
    width: 80%;
  }
}
@media screen and (max-height: 500px) {
  .contactcontainer.ismobile .container .info {
    left: 15% !important;
  }
  .contactcontainer.ismobile .container .info #cities {
    margin-left: auto;
    width: 140px;
  }
  .contactcontainer.ismobile .container .info #cities h2 {
    font-size: 0.7rem;
    margin-right: 0;
    margin-left: 1rem;
  }
  .contactcontainer.ismobile .container .info #details h3, .contactcontainer.ismobile .container .info #details h3 a {
    font-size: 0.6rem;
    line-height: 0.7rem;
  }
  .contactcontainer.ismobile .container .form {
    -webkit-transform: none;
            transform: none;
    position: relative;
    top: 100px;
  }
}
@media screen and (max-height: 740px) {
  .contactcontainer.ismobile {
    height: auto;
  }
  .contactcontainer.ismobile .container .form {
    position: relative !important;
    -webkit-transform: none;
            transform: none;
    top: 0;
    margin-top: 160px;
  }
  .contactcontainer.ismobile .container .info {
    position: unset;
    margin: auto;
    margin-top: 80px;
  }
}
.text-page {
  margin: 18vh 10vh 5vh 10vh;
  color: white;
}
.text-page h1 {
  word-break: break-word;
  font-family: "Poppins";
  text-align: center;
  font-size: 2rem;
}
.text-page h2 {
  text-align: center;
}
.text-page a {
  color: #a7a7a7;
}
.text-page .content {
  margin-top: 5%;
  word-break: break-word;
}

@media screen and (max-height: 500px) {
  .text-page.ismobile {
    margin: 2vh;
  }
}
@media screen and (max-height: 740px) {
  .text-page.ismobile {
    height: auto;
    margin: 2vh;
  }
}
@media screen and (max-height: 1070px) {
  .text-page.ismobile {
    height: auto;
    margin: 12vh 8vh 0vh 8vh;
  }
}
#http404 {
  position: relative;
  opacity: 0;
  max-width: 1600px;
  margin: auto;
}
#http404 h1 {
  font-size: 100px;
  font-family: Poppins;
  font-weight: 800;
  text-align: center;
  margin: 0;
  padding-top: 10%;
  color: white;
  opacity: 1;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
#http404 h1::before {
  content: "";
  position: absolute;
  right: -20px;
  top: calc(100% - 50px);
  width: 15px;
  height: 15px;
  background-color: #e8bf2f;
  border-radius: 50%;
}
#http404 h1.no-dot::before {
  display: none;
}
#http404 img {
  display: block;
  width: 100%;
  top: 60vh;
  -webkit-transform: none;
          transform: none;
  position: absolute;
  -webkit-transform: translateY(-10vh);
          transform: translateY(-10vh);
  margin: 0;
  z-index: -1;
}
#http404 .content {
  width: 500px;
  max-width: 100vw;
  margin: auto;
  text-align: center;
}
#http404 .content * {
  color: white;
  margin: 5px 0;
}
#http404 .content h3 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
#http404 .content h4 {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.8;
}

#browserunsupported {
  position: relative;
  opacity: 0;
  margin-top: 20px;
}
#browserunsupported h1 {
  font-size: 80px;
  line-height: 80px;
  font-family: Poppins;
  font-weight: 600;
  text-align: center;
  margin: 0;
  color: white;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
#browserunsupported img {
  -webkit-filter: invert(1);
          filter: invert(1);
  display: block;
  width: 125px;
  position: absolute;
  left: 50%;
  top: 240px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
#browserunsupported .content {
  width: 500px;
  max-width: 100vw;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 450px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
#browserunsupported .content * {
  color: white;
  margin: 5px 0;
}
#browserunsupported .content h2 {
  font-size: 45px;
  line-height: 45px;
  font-family: Poppins;
}
#browserunsupported .content h4 {
  font-size: 18px;
  font-weight: 400;
  opacity: 0.8;
  width: 350px;
  max-width: 100%;
  margin: auto;
  margin-top: 20px;
}

@media screen and (max-width: 1070px) {
  #http404 h1 {
    padding-top: 20%;
  }
}
@media screen and (max-width: 500px) {
  #http404 h1 {
    padding-top: 40%;
    font-size: 80px !important;
  }
  #http404 h3 {
    font-size: 1rem !important;
  }
}
@-webkit-keyframes ghostMovement {
  0% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  50% {
    -webkit-transform: translate(-50%, -10px);
            transform: translate(-50%, -10px);
  }
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}
@keyframes ghostMovement {
  0% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
  50% {
    -webkit-transform: translate(-50%, -10px);
            transform: translate(-50%, -10px);
  }
  100% {
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
  }
}
@media screen and (max-width: 1070px) {
  .menus #menuitems {
    display: none;
  }
  .menus .menu-full {
    display: grid;
  }
  .menus .menu-full.french-menu-full #content ul {
    font-size: 2.5rem;
  }
  .menus .burger {
    display: flex;
    top: 35px;
  }
}
@media screen and (max-width: 500px) {
  .menus .menu-full #map {
    display: none;
  }
  .menus .menu-full #content ul {
    text-align: left;
    font-size: 50px;
    line-height: 4rem;
    top: 45%;
  }
  .menus .menu-full #content ul li #merge-logo {
    position: relative;
    top: 0.4rem;
    left: 3px;
  }
  .menus #hc-logo {
    margin: 40px;
    z-index: 101;
  }
}
@media screen and (max-height: 600px) {
  .menus .menu-full #content ul li {
    font-size: 30px;
    line-height: 40px;
  }
  .menus .menu-full #content ul #merge-logo {
    height: 25px;
    position: relative;
    top: 7px;
    left: 0px;
  }
}
