.projectdetails {
  .header {
    width: 100%;
    height: 100vh;
    min-height: 800px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    #cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(10px);
      transition: all 0.2s ease;
    }

    #description {
      position: absolute;
      top: 0;
      color: white;
      left: 50%;
      top: calc(50% - 20px);
      transform: translate(-50%, -50%);

      h1 {
        font-size: 3rem;
        text-transform: uppercase;
        margin: 0;
      }

      h4 {
        text-align: center;
        font-family: "Source Sans Pro";
        font-weight: 100;
        margin: 0;
      }
    }
  }
}

.projectdetailcontainer {
  background-color: white;
}

#projectdetailglass {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(17, 17, 17, 1);
  backdrop-filter: blur(20px);
  z-index: 100;

  pointer-events: none;
  transition: all 1s ease;
}

.projectsectionhidden {
  // opacity: 0;
  // transform: translateY(20px);

  transition: all 0.2s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.loaded #projectdetailglass {
  backdrop-filter: blur(20px);
  background-color: $secondary;

  animation: revealGlass 2.5s forwards;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);

  &.black {
    background-color: $secondary !important; // glass blur only on chrome & safari
  }
}

@keyframes revealGlass {
  0% {
    background-color: $secondary;
    transform: translateX(0);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.8);
    transform: translateX(0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.8);
    transform: translateX(-100vw);
  }
}

.section-line {
  position: absolute;
  left: 80px;
  top: 10%;
  width: fit-content;
  font-weight: 800;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    right: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
    width: 70px;
    height: 2px;
    background-color: black;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 55%;
    height: 10px;
    width: 95px;
    background-color: $primary;
    z-index: -1;
  }
}

#projectclosebuttoncontainer {
  h4 {
    font-weight: 300;
    font-size: 1rem;

    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-140%, -50%);

    margin: 0;
    color: black;
  }

  .projectclosebutton {
    position: fixed;
    top: 25px;
    right: 25px;

    display: flex;
    align-items: center;

    color: white;
    font-size: 1.25rem;
    backdrop-filter: blur(10px);

    width: 45px;
    height: 45px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;

    z-index: 1;
    cursor: pointer;

    transition: all 0.2s ease;

    &.visible {
      opacity: 0.8;
      transform: translateY(0);
    }

    svg {
      margin: auto;
      stroke-width: 2px;
      transition: all 0.2s ease;

      pointer-events: none;
    }

    &:hover {
      opacity: 1;
      // svg{ transform: rotate(90deg); }
    }
  }
}

#project-kruger {
  $color1: #8bc559;
  $color2: #6fa2d6;
  $color3: #0571df;
  $color4: #fdc500;
  $color5: #f16450;
  $color6: #07d7a9;
  $color7: #13c8e9;
  $color8: #f8146f;
  $color9: #3a5d80;
  $color10: #ff543b;
  $color11: #37b33a;
  $color12: #b381f1;

  $banner-height: 80vh;

  .banner {
    width: 100%;
    height: $banner-height;
    background-color: #3aab48;
    background-image: url("~assets/images/projects/kruger/1_landingpage.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .banner-fr {
    background-image: url("~assets/images/projects/kruger/1_landingpage-fr.png");
  }

  .services {
    width: 100%;
    height: calc(100vh - #{$banner-height});
    background-color: #188d42;

    display: flex;

    div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      max-width: 800px;
      color: white;
      margin: auto;

      h4 {
        font-weight: 400;
        text-align: center;
        margin: 10px;

        display: flex;
        align-items: center;
      }
    }
  }

  .description {
    padding: 80px 120px;
    margin-bottom: 40px;

    img {
      max-width: 200px;
      display: block;
      margin: 0 auto;
    }

    p {
      max-width: 560px;
      margin: auto;
      margin-top: 60px;
      margin-bottom: 60px;
      text-align: center;
    }
  }

  .laptop {
    width: 100%;
    background-color: #dee0de;

    img {
      width: 100%;
      object-fit: contain;
      display: block;
    }
  }

  .palette {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    .color {
      position: relative;
      padding-bottom: 100%;

      div {
        position: absolute;
        bottom: 0;
        right: 0;

        margin: 12%;

        h4 {
          font-weight: 400;
          font-size: 0.6rem;
          color: white;
          margin: 0;
          margin-bottom: 0px;
        }
      }
    }
  }

  .illustrations {
    background-color: $color4;

    .container {
      max-width: 800px;
      margin: auto;
      padding-top: 150px;
      padding-bottom: 150px;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      div {
        width: 100%;
        transform: scale(0.9);

        img {
          width: 100%;
        }
      }
    }
  }

  .wireframe {
    width: 100%;
    height: 800px;

    display: flex;
    align-items: center;
    padding-top: 60px;

    position: relative;

    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .indicator {
      @extend .section-line;
    }
  }

  .phone-mockup {
    width: 100%;
    height: 800px;

    display: flex;
    align-items: center;

    background-color: $color5;

    img {
      width: 100%;
      max-width: 1000px;
      margin: auto;
    }
  }

  .tablet-mockup {
    height: 800px;
    background-color: $color2;
    display: flex;
    align-items: center;

    .container {
      max-width: 800px;
      display: grid;
      grid-template-columns: 0.55fr 0.45fr;
      grid-column-gap: 25px;
      margin: auto;

      img {
        width: 100%;
        display: flex;
        align-self: center;

        filter: drop-shadow(5px 2px 14px rgba(0, 0, 0, 0.5));
      }
    }
  }

  .wireframe-box {
    height: 800px;
    width: 100%;

    display: flex;
    align-items: center;
    position: relative;

    img {
      width: 90%;
      margin: auto;

      max-height: 100%;
      object-fit: contain;
    }

    .indicator {
      @extend .section-line;
    }
  }

  .products1 {
    width: 100%;
    // min-height: 500px;
    display: grid;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .products2 {
    min-height: 300px;
    display: grid;
    grid-template-columns: 0.45fr 0.55fr;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .products3 {
    width: 100%;
    min-height: 500px;
    display: grid;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }

  .photoshoot {
    width: 100%;
    min-height: 90vw;
    display: grid;
    grid-template-columns: 1fr 1fr;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .productvideo {
    margin: 40px;
    margin-bottom: 0;
    padding-bottom: 40px;

    * {
      outline: none;
    }
  }

  .indicatorcontainer {
    margin: 5% auto;
    position: relative;

    .indicator {
      @extend .section-line;

      position: relative;
    }
  }
}


#project-kruger-afh {

  $banner-height: 650px;

  .banner {
    width: 100%;
    height: $banner-height;

    font-family: Poppins;
    font-size: 4rem;
    color: #e0ae25;
    text-align: center;
    margin: auto;

    display: flex;
    align-items: center;
    position: relative;

    h1{
      margin: auto;
      z-index: 1;
      position: relative;

      span{
        color: white;
      }
    }

    img{
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
    }
  }

  .services {
    width: 100%;
    min-height: 120px;
    background-color: #DFDFDF;
    display: flex;

    div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: 800px;
      margin: auto;

      h4 {
        font-weight: 400;
        text-align: center;
        margin: 0 auto;
        padding: 10px;
        color: black;
        display: flex;
        align-items: center;
      }
    }
  }

  .description {
    padding: 80px 120px;
    margin-bottom: 40px;

    img {
      max-width: 500px;
      display: block;
      margin: 0 auto;
    }

    p {
      max-width: 560px;
      margin: auto;
      margin-top: 60px;
      margin-bottom: 60px;
      text-align: center;
    }
  }

  .papertowels{
    position: relative;

    .indicator {
      @extend .section-line;
    }

    img{
      width: 100%;
      display: block;
    }
  }

  .gloomytowels{
    position: relative;

    .indicator {
      @extend .section-line;
      color: white;

      &::before{
        background-color: white;
      }
    }

    img{
      width: 100%;
      display: block;
    }
  }

  .towelicons{
    position: relative;
    background-color: black;
    padding: 60px;
    padding-top: 120px;
    padding-bottom: 120px;

    .indicator {
      @extend .section-line;
      color: white;

      &::before{
        background-color: white;
      }
    }

    img{
      width: 80%;
      margin: auto;
      display: block;
    }
  }

  .copywriting{
    position: relative;
    background-color: black;
    padding: 60px;
    padding-top: 120px;
    padding-bottom: 120px;

    .indicator {
      @extend .section-line;
      color: white;

      &::before{
        background-color: white;
      }
    }

    img{
      width: 100%;
      margin: auto;
      display: block;
    }
  }

  .infographic{
    position: relative;
    background-color: black;

    .indicator {
      @extend .section-line;
      color: white;
      transform: translateY(-100px);

      &::before{
        background-color: white;
      }
    }

    img{
      width: 100%;
      margin: auto;
      display: block;
      pointer-events: none;
    }
  }

  .socialposts{
    position: relative;

    .indicator {
      @extend .section-line;
    }

    img#tablet{
      width: 100%;
      display: block;
    }

    img#phone{
      position: absolute;
      bottom: 0%;
      right: 0px;
      width: 45%;
      height: auto;
    }
  }

}

#project-tea {
  .banner {
    width: 100%;
    padding-top: 60px;

    #logo1 {
      max-width: 300px;
      display: block;
      margin: auto;
    }

    #logo2 {
      display: block;
      width: 780px;
      max-width: 80%;
      margin: 80px auto;
    }

    p {
      width: 820px;
      max-width: 90%;
      margin: auto;
      margin-bottom: 80px;
      text-align: center;
    }
  }

  .services {
    width: 100%;
    background-color: black;

    display: flex;

    div {
      display: flex;
      max-width: 600px;
      width: 90%;
      color: white;
      margin: auto;
      padding: 40px 0;

      h4 {
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin: 10px;

        display: grid;
        align-items: center;
        flex: 1;
        text-align: center;
      }
    }
  }

  .indicatorcontainer {
    margin: 10% auto;
    position: relative;

    .indicator {
      @extend .section-line;

      position: relative;
    }
  }

  .blackandwhite {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      display: block;
    }
  }

  .colorgrid {
    $colorgrid-gutter: 10px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $colorgrid-gutter;
    grid-row-gap: $colorgrid-gutter;
    margin: $colorgrid-gutter;

    .wallpaper {
      position: relative;

      &::before {
        content: "";
        position: relative;
        top: 0;
        width: 100%;
        height: 80px;

        display: grid;
      }

      &:nth-child(1)::before {
        background-color: #d858a0;
      }
      &:nth-child(2)::before {
        background-color: #f7b61b;
      }
      &:nth-child(3)::before {
        background-color: #3ea448;
      }
      &:nth-child(4)::before {
        background-color: #8261a9;
      }

      &::after {
        content: "";
        position: absolute;
        color: white;
        top: 40px;
        right: 20px;
        transform: translateY(-50%);
      }

      &:nth-child(1)::after {
        content: "#D858A0";
      }
      &:nth-child(2)::after {
        content: "#F7B61B";
      }
      &:nth-child(3)::after {
        content: "#3EA448";
      }
      &:nth-child(4)::after {
        content: "#8261A9";
      }

      img {
        width: 100%;
        max-height: 10vw;
        height: 180px;
        object-fit: cover;
        object-position: top;
        display: block;
      }
    }
  }

  .fontface {
    width: 100%;
    min-height: 600px;
    display: flex;
    align-items: center;
    position: relative;

    img {
      width: 80%;
      max-width: 600px;
      display: block;
      margin: auto;
      object-fit: contain;
    }

    .indicator {
      @extend .section-line;
    }
  }

  .photo1 {
    width: 100%;
    position: relative;

    img {
      width: 100%;
      object-fit: contain;
    }

    .indicator {
      @extend .section-line;
    }
  }

  .photo2 {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 40px 0;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .photo3 {
    background-color: #efefef;
    padding: 100px;
    padding-top: 160px;
    position: relative;

    div {
      max-width: 1000px;
      margin: auto;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 100px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .indicator {
      @extend .section-line;
    }
  }

  .photo4 {
    background-color: #f1f4bd;
    position: relative;

    img {
      display: block;
      margin: auto;
      width: 100%;
      object-fit: cover;
    }

    .indicator {
      @extend .section-line;

      &::after {
        content: none;
      }
    }
  }
}

#project-bdo {
  $bdo-primary: #e8213b;

  .indicatorcontainer {
    margin: 40px auto;
    position: relative;

    .indicator {
      @extend .section-line;

      position: relative;
    }
  }

  .banner {
    width: 100%;

    img {
      display: block;
      width: 100%;
      object-fit: contain;
    }
  }

  .services {
    width: 100%;
    background-color: #223e81;

    display: flex;

    div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: 900px;
      width: 90%;
      color: white;
      margin: auto;
      padding: 40px 0;

      h4 {
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin: 10px 60px;

        display: grid;
        align-items: center;
        flex: 1;
        text-align: center;
      }
    }
  }

  .description {
    width: 80%;
    max-width: 600px;
    margin: auto;
    padding: 80px 0;

    img {
      width: 100%;
      max-width: 120px;
      object-fit: contain;
      display: block;
      margin: auto;
      margin-top: 20px;
    }

    p {
      text-align: center;
      margin: 50px auto;
    }
  }

  .laptop {
    width: 100%;
    background-color: $bdo-primary;
    position: relative;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      display: block;
    }

    .indicator {
      @extend .section-line;
      color: white;

      &::before {
        background-color: white;
        height: 1px;
      }
      &::after {
        content: none;
      }
    }
  }

  .wireframe {
    width: 100%;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .productvideo {
  }

  .photo1 {
    width: 100%;
    position: relative;

    img {
      width: 100%;
      object-fit: contain;
      display: block;
    }

    .indicator {
      @extend .section-line;
    }
  }

  .photo2 {
    width: 100%;
    img {
      width: 100%;
      object-fit: contain;
      display: block;
    }
  }

  .photo3 {
    width: 100%;
    img {
      width: 100%;
      object-fit: contain;
      display: block;
    }
  }

  .productvideo2 {
    .react-player__preview {
      background-size: contain !important;
      background-repeat: no-repeat;
      background-color: #111111;
    }
  }
}

#project-auction {
  $auction-primary: #2cade3;

  .indicatorcontainer {
    margin: 10% auto;
    margin-bottom: 40px;
    position: relative;

    .indicator {
      @extend .section-line;

      position: relative;
    }
  }

  .player-wrapper {
    position: relative;
    // padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
    padding-top: 52.25%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .banner {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    video {
      display: block;
      min-width: 100%;
    }
  }

  .services {
    width: 100%;
    height: auto;
    padding: 20px 0;
    background-color: #2cade3;

    display: flex;

    div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: 800px;
      color: white;
      margin: auto;

      h4 {
        font-weight: 400;
        text-align: center;
        margin: 10px;

        display: flex;
        align-items: center;
      }
    }
  }

  .description {
    padding: 80px 120px;
    margin-bottom: 40px;

    img {
      max-width: 200px;
      display: block;
      margin: 0 auto;
    }

    #meritlogo {
      max-width: 500px;
      width: 200px;

      margin-bottom: 80px;
    }

    p {
      max-width: 560px;
      margin: auto;
      margin-top: 60px;
      margin-bottom: 60px;
      text-align: center;

      span {
        color: $auction-primary;
      }
    }
  }

  .illustrations {
    width: 100%;
    margin-bottom: 80px;

    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 1200px;
      margin: auto;

      img {
        width: 100%;
        height: 500px;
        object-fit: contain;
      }
    }
  }

  .laptop,
  .tablet-mockup {
    width: 100%;

    img {
      width: 100%;
      display: block;
    }
  }
}

#project-kfc {
  $kfc-primary: #ee2c2a;

  .indicatorcontainer {
    margin: 10% auto;
    margin-bottom: 40px;
    position: relative;

    .indicator {
      @extend .section-line;

      position: relative;
    }
  }

  .banner {
    width: 100%;
    position: relative;

    #mainlogo {
      position: absolute;
      left: 50%;
      top: 20px;
      transform: translateX(-50%);

      max-width: 350px;
    }

    .container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: flex-end;

      min-height: 300px;
      padding-top: 80px;

      img {
        width: 90%;
        height: 100%;
        margin: 0 auto;

        object-fit: contain;
        object-position: bottom;

        &:nth-child(1) {
          height: 500px;
        }
        &:nth-child(2) {
          height: auto;
          transform: scale(0.8);
          transform-origin: bottom;
        }
        &:nth-child(3) {
          height: auto;
          max-width: 70%;
          margin: 0 auto;
        }
      }
    }
  }

  .description {
    padding: 80px 120px;
    margin-bottom: 40px;

    margin-bottom: 0;
    padding-bottom: 50px;

    img {
      max-width: 150px;
      display: block;
      margin: 40px auto;
    }

    p {
      max-width: 560px;
      margin: auto;
      margin-top: 60px;
      margin-bottom: 60px;
      text-align: center;
    }
  }

  .services {
    width: 100%;
    padding: 30px 0;
    background-color: #111111;

    display: flex;

    div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      max-width: 95%;
      color: white;
      margin: auto;

      h4 {
        font-weight: 600;
        text-align: center;
        margin: 10px;

        display: grid;
        align-items: center;
      }
    }
  }

  .picture {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .wireframe {
    width: 100%;
    height: 800px;

    display: flex;
    align-items: center;
    position: relative;

    img {
      width: 100%;
      max-width: 80%;
      margin: auto;
    }

    .indicator {
      @extend .section-line;
    }
  }

  .laptop {
    width: 100%;
    background-color: $kfc-primary;
    position: relative;

    img {
      width: 80%;
      display: block;
      margin: auto;
    }

    .indicator {
      @extend .section-line;
      &::after {
        content: none;
      }
    }
  }

  .tablets {
    width: 100%;

    img {
      width: 100%;
      display: block;
      margin: auto;
    }
  }

  .kfc-icons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    max-width: 90%;
    margin: auto;
    margin-bottom: 80px;
    align-items: flex-end;

    img {
      width: 60%;
      object-fit: contain;
      margin: 0 auto;
    }
  }

  .snapchat {
    width: 100%;
    background-image: url("~assets/images/projects/kfc/snapchat-background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    margin: 0;

    img {
      display: block;
      margin: auto;
      max-width: 60%;
      width: 800px;

      transform: scale(1.5);
    }
  }

  .gallery-item {
    width: 100%;
    margin-top: 20px;

    img {
      width: 100%;
      display: block;
    }

    &.split {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

#project-carrot {
  $carrot-primary: #fe9247;
  $carrot-secondary: #07184f;

  .indicatorcontainer {
    margin: 40px auto;
    position: relative;

    .indicator {
      @extend .section-line;

      position: relative;
      display: block;
    }
  }

  video {
    display: block;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .banner {
    width: 100%;
  }

  .services {
    width: 100%;
    background-color: $carrot-secondary;

    display: flex;

    div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      max-width: 95%;
      color: white;
      margin: auto;
      padding: 40px 0;

      h4 {
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;

        display: grid;
        align-items: center;
        flex: 1;
        text-align: center;
      }
    }
  }

  .description {
    width: 90%;
    max-width: 1000px;
    margin: auto;
    padding: 80px 0;

    p {
      text-align: center;
      margin: 50px auto;

      span {
        color: $carrot-primary;
      }
    }
  }

  .phone {
    width: 100%;
    background-color: $carrot-secondary;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      display: block;
    }
  }

  .photo1 {
    background-color: #f0f5f8;
    padding: 80px 0;
    position: relative;

    div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 1000px;
      margin: auto;
      align-items: center;

      img {
        width: 100%;
        object-fit: contain;

        &:nth-child(2) {
          min-height: 400px;
        }
      }
    }

    .indicator {
      @extend .section-line;
    }
  }

  .photo2 {
    width: 100%;
    position: relative;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      display: block;
    }

    .indicator {
      @extend .section-line;

      color: white;

      &::before {
        background-color: white;
        height: 1px;
      }
      &::after {
        content: none;
      }
    }
  }
}

#project-rexel {
  $rexel-primary: #143d8d;

  .indicatorcontainer {
    margin-bottom: 40px;
    position: relative;

    .indicator {
      @extend .section-line;

      position: relative;
    }
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .banner {
    width: 100%;

    video {
      display: block;
    }
  }

  .services {
    width: 100%;
    background-color: $rexel-primary;

    display: flex;

    div {
      display: flex;
      max-width: 800px;
      width: 90%;
      color: white;
      margin: auto;
      padding: 40px 0;

      h4 {
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin: 10px;

        display: grid;
        align-items: center;
        flex: 1;
        text-align: center;
      }
    }
  }

  .description {
    max-width: 800px;
    width: 90%;
    margin: auto;

    padding: 80px 0px;

    img {
      max-width: 180px;
      display: block;
      margin: auto;
    }

    p {
      text-align: center;
      margin: 60px auto;
    }
  }

  .photo1,
  .photo2 {
    width: 100%;
    position: relative;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      display: block;
    }

    .indicator {
      @extend .section-line;
    }
  }

  .photo1 {
    .indicatorcontainer {
      margin: 40px 0;
    }
  }

  .photo2 {
    .indicatorcontainer {
      margin-top: 40px;
    }
  }
}

#project-runottawa {
  $color1: #ec2890;
  $color2: #c01e8d;
  $color3: #4b499d;
  $color4: #ffe300;
  $color5: #f4831f;
  $color6: #40b9eb;

  .indicatorcontainer {
    margin: 10% auto;
    position: relative;

    .indicator {
      @extend .section-line;

      position: relative;
    }
  }

  .banner {
    width: 100%;
    height: 700px;
    background-image: url(~assets/images/projects/runottawa/banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    position: relative;

    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
      object-position: center;
      margin: auto;
      display: block;
      position: absolute;
      left: calc(50% - 40px);
      bottom: 180px;
      transform: translateX(-50%);

      z-index: 1;
      pointer-events: none;
    }

    h1 {
      font-size: 16vw;
      align-self: flex-end;
      margin: 10vw auto;
      color: white;

      text-align: center;
      line-height: 12.4vw;
    }
  }

  @media screen and (min-width: 1100px) {
    .banner{
      img{
        bottom: 240px;
      }
    }
  }
  @media screen and (max-width: $screen-l) {
    .bannner{
      img {
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }

  .services {
    width: 100%;
    background-color: rgb(230, 230, 230);

    display: flex;

    div {
      display: flex;
      max-width: 800px;
      width: 90%;
      color: black;
      margin: auto;
      padding: 40px 0;
      flex-wrap: wrap;

      h4 {
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin: 10px;

        display: grid;
        align-items: center;
        flex: 1;
        text-align: center;
      }
    }
  }

  .description {
    padding: 60px;

    img {
      max-width: 100%;
      width: 400px;
      margin: 60px auto;
      display: block;
    }

    p {
      max-width: 480px;
      margin: auto;
      margin-bottom: 60px;
      text-align: center;
      line-height: 20px;
    }
  }

  #palette {
    position: relative;

    .indicatorcontainer {
      margin: 0;
      position: absolute;
      top: 20px;

      font-weight: 600;
    }

    #colors {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      div {
        position: relative;

        &::before {
          content: "";
          display: block;
          padding-bottom: 100%;
        }

        span {
          position: absolute;
          right: 20px;
          bottom: 20px;
          color: white;
        }

        &:nth-child(1) {
          background-color: $color1;
          background-image: url(~assets/images/projects/runottawa/gradient-tile.jpg);
          background-size: cover;
          grid-row: 1 / span 2;
          grid-column: 1 / span 2;
        }
        &:nth-child(2) {
          background-color: $color1;
        }
        &:nth-child(3) {
          background-color: $color2;
        }
        &:nth-child(4) {
          background-color: $color3;
        }
        &:nth-child(5) {
          background-color: $color4;
        }
        &:nth-child(6) {
          background-color: $color5;
        }
        &:nth-child(7) {
          background-color: $color6;
        }
      }
    }
  }

  .wireframe-box {
    width: 100%;
    height: 800px;

    display: flex;
    align-items: center;
    padding-top: 60px;

    position: relative;

    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .indicator {
      @extend .section-line;
    }
  }

  .splash-screen,
  .home-screen {
    position: relative;

    background-image: url(~assets/images/projects/runottawa/splash-background.jpg);
    background-size: cover;

    .indicator {
      @extend .section-line;
    }

    img {
      max-width: 100%;
      margin: auto;
      display: block;
      padding: 100px 0;
    }
  }
  .role-screen,
  .selfie-filters,
  .final-logos {
    position: relative;

    .indicator {
      @extend .section-line;
    }

    img {
      max-width: 100%;
      margin: auto;
      display: block;
      padding: 100px 0;
    }
  }

  .champ {
    background-image: url(~assets/images/projects/runottawa/08_BG.png);
    background-size: cover;
    padding: 40px;

    img {
      max-width: 100%;
    }
  }

  .brand-process {
    position: relative;

    .indicator {
      @extend .section-line;
    }

    img {
      max-width: 100%;
      margin: auto;
      display: block;
      padding: 100px 0;
    }
  }

  .brand-palette {
    $brand-color1: #ff0000;
    $brand-color2: #f38ba0;
    $brand-color3: #f8e400;
    $brand-color4: #b9e4db;
    $brand-color5: #1b4d4e;
    $brand-color6: #1d3847;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    div {
      position: relative;

      &::before {
        content: "";
        display: block;
        padding-bottom: 100%;
      }

      span {
        position: absolute;
        right: 15px;
        bottom: 20px;
        color: white;
        font-size: 0.8rem;
      }

      &:nth-child(1) {
        background-color: $brand-color1;
      }
      &:nth-child(2) {
        background-color: $brand-color2;
      }
      &:nth-child(3) {
        background-color: $brand-color3;
      }
      &:nth-child(4) {
        background-color: $brand-color4;
      }
      &:nth-child(5) {
        background-color: $brand-color5;
      }
      &:nth-child(6) {
        background-color: $brand-color6;
      }
    }
  }

  #brand-palette-images{
    width: 100%;
  }

  .social-assets{

    position: relative;
    background-color: #1d3847;
    width: 100vw;
    overflow: hidden;

    .indicator {
      @extend .section-line;

      z-index: 10;
      top: 80px;

      &::after{
        display: none;
      }
    }

    .color-container{
      width: 100%;
      position: relative;

      img{
        width: 100%;
        display: block;
        position: relative;
      }

      &:nth-child(2) .bg { z-index: 3; }
      &:nth-child(3) .bg { z-index: 2; }
      &:nth-child(4) .bg { z-index: 1; }

      &:nth-child(3),
      &:nth-child(4){
        margin-top: -60vw;
      }

      .phone1,
      .phone2,
      .phone3{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

        z-index: 10;
      }

      .phone1{
        transform: translate(-50%, -50%) translateY(-10%) scale(0.9);
      }

      .phone2{
        transform: translate(-50%, -50%) translateY(-20%);
      }

      .phone3{
        transform: translate(-50%, -50%) translateY(-35%) scale(1.2) rotate(-8deg);
      }

    }

    .phone4{
      width: 100%;

      margin-top: -50%;
      z-index: 5;
      position: relative;
      transform: scale(1.2);
    }
  }


}

@media screen and (min-width: $screen-xl) {
  #project-kruger {
    .products1 {
      min-height: 800px;
    }
    .products2 {
      min-height: 380px;
    }
    .products3 {
      min-height: 800px;
    }
  }
}

@media screen and (min-width: $screen-xxl) {
  #project-kfc {
    .wireframe {
      img {
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: $screen-l) {
  #project-auction {
    .services {
      div {
        grid-template-columns: 1fr;

        h4 {
          display: block;
        }
      }
    }
  }

  #project-bdo {
    .services {
      div {
        width: 100%;

        h4 {
          margin: 10px;
        }
      }
    }
  }

  #project-carrot {
    .services {
      div {
        grid-template-columns: 1fr;
      }
    }
  }

  #project-runottawa {

    .banner{
      height: 600px;
    }

    #palette {
      .indicatorcontainer{
        transform: translateY(-50px);
      }

      #colors {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        div {
          &:nth-child(1) {
            grid-row: 1 / span 2;
            grid-column: 1 / span 1;
          }
        }
      }
    }

    .brand-palette {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media screen and (max-width: $screen-m) {
  .projectclosebutton {
    // always visible
    opacity: 0.8;
    transform: translateY(0);
  }

  #project-kruger {
    .description {
      padding: 80px 20px;
    }

    .services {
      div {
        grid-template-columns: 1fr 1fr;

        h4 {
          margin: 10px auto;
        }
      }
    }

    .palette {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .illustrations {
      .container {
        grid-template-columns: 1fr 1fr;
      }
    }

    .wireframe {
      height: 500px;
    }

    .tablet-mockup {
      height: auto;

      .container {
        grid-template-columns: 1fr;
        padding: 20%;
      }
    }

    .products1 {
      height: unset;

      img {
        height: unset;
        min-height: unset;
      }
    }

    .products2 {
      grid-template-columns: 1fr;

      img {
        height: 400px;
      }
    }

    .products3 {
      min-height: unset;

      img {
        height: 400px;
      }
    }

    .photoshoot {
      min-height: unset;

      img {
        height: auto;
      }
    }
  }

  #project-auction {
    .illustrations {
      .container {
        grid-template-columns: 1fr;
      }
    }

    .description {
      padding: 80px 40px;
    }
  }

  #project-kfc {
    .banner {
      .container {
        grid-template-columns: 1fr 1fr;

        img {
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }

    .services {
      div {
        grid-template-columns: 1fr;
      }
    }

    .wireframe {
      height: unset;
      padding: 100px 0;
    }

    .description {
      padding: 80px 40px;
    }
  }

  #project-bdo {
    .services {
      div {
        grid-template-columns: 1fr;
      }
    }
  }

  #project-tea {
    .colorgrid {
      grid-template-columns: 1fr;

      .wallpaper {
        img {
          max-height: 100px;
        }
      }
    }

    .photo1 {
      .indicator {
        transform: translateY(-80px);
      }
    }

    .photo3 {
      div {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }
    }
  }

  #project-kruger-afh{
    .banner{
      h1{
        font-size: 5rem;
      }
    }
    .description{
      img{
        max-width: 90%;
      }
      padding: 80px 40px;
    }

    .towelicons{
      img{
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $screen-s) {
  .projectclosebutton {
    top: 15px;
    right: 15px;

    background-color: transparent;
    box-shadow: none;
  }

  #project-kruger {
    .services {
      height: unset;
      padding: 20px 0;

      div {
        grid-template-columns: 1fr;
      }
    }

    .palette {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .phone-mockup {
      height: 600px;
    }

    .wireframe-box {
      height: 500px;
    }
  }

  #project-kfc {
    .banner {
      .container {
        grid-template-columns: 1fr;
        padding-top: 160px;

        img {
          &:nth-child(2) {
            max-width: 350px;
            margin-top: 60px;
          }
        }
      }
    }

    .wireframe {
      img {
        max-width: 100%;
      }
    }
  }

  #project-rexel {
    .services {
      div {
        flex-direction: column;
      }
    }
  }

  #project-carrot {
    .photo1 {
      div {
        grid-template-columns: 1fr;

        img {
          width: 70%;
          margin: auto;

          &:first-child {
            margin-top: 80px;
          }
        }
      }
    }
  }

  .services {
    div {
      h4 {
        font-size: 0.8rem;
        line-height: 0.8rem;
      }
    }
  }

  .indicator {
    font-size: 0.8rem;
  }
}
